import moment from "moment";

import { BootstrapContextProps } from "../hooks/bootstrap";
import { _ } from "../languages/helper";
import { MetricDescription } from "../types/synthesizer";

import { DateRange, getDatesByGranularity, Granularity } from "./dateUtils";

export const CHART_COLORS = [
  "#332A9A",
  "#493DC3",
  "#7C73E6",
  "#ABA4E8",
  "#CDC8F5",
  "#F0C1CC",
  "#FFD4C9",
];

const CHART_COLORS_COMPARE = [
  "#332A9A88",
  "#493DC388",
  "#7C73E688",
  "#ABA4E888",
  "#CDC8F588",
  "#F0C1CC88",
  "#FFD4C988",
];

export const pickColor = (id: number, max: number, comparing = false) => {
  return comparing
    ? CHART_COLORS_COMPARE[pickColorIndex(id, max)]
    : CHART_COLORS[pickColorIndex(id, max)];
};

export const pickColorIndex = (id: number, max: number) => {
  if (max < CHART_COLORS.length) {
    switch (max) {
      case 1:
        return 3;
      case 2:
        return [2, 6][id];
      case 3:
        return [1, 3, 5][id];
      case 4:
        return [0, 2, 4, 6][id];
      case 5:
        return [0, 2, 4, 5, 6][id];
    }
  }
  return id % CHART_COLORS.length;
};
interface RemoveOptions {
  removeDiscounts?: boolean;
  removeRefunds?: boolean;
  removeShipping?: boolean;
  removeTax?: boolean;
  removeTip?: boolean;
  removeCostOfProduct?: boolean;
  removeExpenses?: boolean;
}

const DEFAULT_REMOVE_OPTIONS: RemoveOptions = {
  removeDiscounts: false,
  removeRefunds: false,
  removeShipping: false,
  removeTax: false,
  removeTip: false,
  removeCostOfProduct: false,
  removeExpenses: false,
};

export const getTooltipElements = (
  getUserTenantSetting: BootstrapContextProps["getUserTenantSetting"],
  removeOptions: RemoveOptions = DEFAULT_REMOVE_OPTIONS,
  openParenthesis = false,
) => {
  const {
    removeDiscounts,
    removeRefunds,
    removeShipping,
    removeTax,
    removeTip,
    removeCostOfProduct,
    removeExpenses,
  } = { ...DEFAULT_REMOVE_OPTIONS, ...removeOptions };
  const elements: MetricDescription[] = [
    {
      image: "Shopify",
      label: _`Gross Sales`,
      operator: "",
      openParenthesis,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Discounts`,
      operator: "-",
      disabled:
        removeDiscounts ||
        getUserTenantSetting("revenue_computation_include_discounts", 0) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Returns`,
      operator: "-",
      disabled:
        removeRefunds ||
        getUserTenantSetting("revenue_computation_include_refunds", 0) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Shipping`,
      operator: "+",
      disabled:
        removeShipping ||
        getUserTenantSetting("revenue_computation_include_shipping", 0) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Tax`,
      operator: "+",
      disabled:
        removeTax ||
        getUserTenantSetting("revenue_computation_include_tax", 0) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Tips`,
      operator: "+",
      disabled:
        removeTip ||
        getUserTenantSetting("revenue_computation_include_tip", 0) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Cost of products`,
      operator: "-",
      disabled:
        removeCostOfProduct ||
        getUserTenantSetting(
          "revenue_computation_deduct_cost_of_products",
          0,
        ) !== 1,
      fixed: true,
    },
    {
      image: "Shopify",
      label: _`Expenses`,
      operator: "-",
      disabled:
        removeExpenses ||
        getUserTenantSetting("revenue_computation_deduct_expenses", 0) !== 1,
      fixed: true,
    },
  ];

  return elements.sort((e1, e2) =>
    e1.disabled && !e2.disabled ? 1 : !e1.disabled && e2.disabled ? -1 : 0,
  );
};

export const getTooltipOrderSettingsElements = (
  getUserTenantSetting: BootstrapContextProps["getUserTenantSetting"],
  currencyFormatter: Function,
) => {
  const elements: MetricDescription[] = [
    { isSubHeading: true, label: _`Details`, fixed: true },
    {
      image: "Shopify",
      operator: "include",
      label: _`cancelled orders`,
      block: true,
      disabled:
        getUserTenantSetting("revenue_computation_include_cancelled", 0) === 0,
    },
    {
      image: "Shopify",
      operator: "include",
      label: `${currencyFormatter(0)} orders`,
      block: true,
      disabled:
        getUserTenantSetting("revenue_computation_include_0_orders", 0) === 0,
    },
  ];

  return elements;
};

type ToPaddedSeriesFactoryArgs = {
  granularity: Granularity;
  weekstart: string;
  range: DateRange;
};

type ToPaddedSeriesArgs<Key extends string> = {
  key: Key;
  points: ({ [key in Key]: number } & { date: string })[];
};

/**
 * Returns a helper that can be used to fill in 0-values for missing
 * dates in a given series.
 */
export const toPaddedSeriesFactory =
  ({ granularity, weekstart, range }: ToPaddedSeriesFactoryArgs) =>
  <Key extends string>({ points, key }: ToPaddedSeriesArgs<Key>): number[] => {
    const dates = getDatesByGranularity(granularity, range, weekstart);

    return dates.map((date) => {
      const v = points.find(
        (d) =>
          moment(date).startOf(granularity).format("YYYY-MM-DD") ===
          moment(d.date).startOf(granularity).format("YYYY-MM-DD"),
      );
      return v?.[key] || 0;
    });
  };
