import moment from "moment";
import { ReactNode } from "react";

import { Grid, Select, Text } from "../../icecube-ux";
import { TextFieldSize } from "../../icecube-ux/shared/baseInputFieldSizeStylesMap";
import { _ } from "../../languages/helper";
import {
  DateRange,
  FILTER_TYPES,
  Granularity,
  SmartDateComparePeriod,
} from "../../utils/dateUtils";
import { SmartFilterSelector } from "../../utils/filterUtils";

import { getSmartDatePickerDisplayValue } from "./selectorUtil";
import SmartDateFilterPicker from "./SmartDateFilterPicker";

import "./smart-date-filter.css";

interface SmartDateFilterProps {
  onApply?: (
    compareRange: DateRange,
    selector: SmartFilterSelector,
    comparePeriod: SmartDateComparePeriod,
  ) => void;
  allowCompare?: boolean;
  withPopup?: boolean;
  size?: TextFieldSize;
  secondary?: boolean;
  matchingDayOfWeek: boolean;
  granularity: Granularity | "none";
  compareRange: DateRange;
  comparePeriod: SmartDateComparePeriod;
  selector: SmartFilterSelector;
  setCompareRange: (value: DateRange) => void;
  setComparePeriod: (value: SmartDateComparePeriod) => void;
  setSelector: (value: SmartFilterSelector) => void;
  setMatchingDayOfWeek: (value: boolean) => void;
  displayDatePicker: boolean;
  setDisplayDatePicker: (display: boolean) => void;
  disableDateRangeSelection?: boolean;
  disableGranularity: boolean;
  showDateRangeWarning?: boolean;
  footerInfoComponent?: ReactNode;
}

export default function SmartDateFilter({
  onApply,
  allowCompare = false,
  withPopup = true,
  size,
  secondary,
  granularity,
  matchingDayOfWeek,
  compareRange,
  comparePeriod,
  selector,
  setCompareRange,
  setSelector,
  setComparePeriod,
  setMatchingDayOfWeek,
  displayDatePicker,
  setDisplayDatePicker,
  disableDateRangeSelection = false,
  disableGranularity,
  showDateRangeWarning = false,
  footerInfoComponent,
}: SmartDateFilterProps) {
  const dateSelectorDisplayValue = getSmartDatePickerDisplayValue(
    selector,
    withPopup,
  );

  return (
    <>
      {displayDatePicker && (
        <SmartDateFilterPicker
          allowCompare={allowCompare}
          onClose={() => setDisplayDatePicker(false)}
          onApply={onApply}
          asPopup={withPopup}
          {...{
            matchingDayOfWeek,
            granularity: disableGranularity ? "none" : granularity,
            compareRange,
            comparePeriod,
            selector,
            setCompareRange,
            setSelector,
            setComparePeriod,
            setMatchingDayOfWeek,
            footerInfoComponent,
          }}
        />
      )}

      {!withPopup && !displayDatePicker && (
        <>
          {selector.tab === FILTER_TYPES.PREDEFINED && (
            <Grid gridTemplateColumns="auto 1fr" gap={8} alignItems="center">
              <Text variant="BodyRegular">From</Text>
              <Select
                forcedDisplayedValue={dateSelectorDisplayValue}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 200 }}
                block
              />
            </Grid>
          )}
          {selector.tab === FILTER_TYPES.RELATIVE && (
            <Grid
              gridTemplateColumns="auto 1fr 1fr"
              gap={8}
              alignItems="center"
            >
              <Text variant="BodyRegular">{_`Last|||plural`}</Text>
              <Select
                placeholder={selector.relativeSelection.count.toString()}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
              <Select
                placeholder={
                  selector.relativeSelection.granularity +
                  (selector.relativeSelection.count === 1 ? "" : "s")
                }
                options={[]}
                selected={[]}
                notOpenable={false}
                onClick={() => setDisplayDatePicker(true)}
                style={{ minWidth: 100 }}
              />
            </Grid>
          )}
        </>
      )}
      {((!displayDatePicker && selector.tab === FILTER_TYPES.RANGE) ||
        withPopup) && (
        <Grid
          gridTemplateColumns={
            "auto 1fr" +
            (allowCompare && window.innerWidth > 1240 ? " auto" : "")
          }
          gap={8}
          alignItems="center"
        >
          <Select
            secondary={secondary}
            disabled={disableDateRangeSelection}
            leftIcon={showDateRangeWarning ? "DateRangeWarning" : "Calendar"}
            size={size}
            forcedDisplayedValue={dateSelectorDisplayValue}
            options={[]}
            selected={[]}
            notOpenable={true}
            onClick={() => setDisplayDatePicker(true)}
            dataAttributes={{ "data-cy": "header-filter-calendar" }}
            block
          />
          {allowCompare && window.innerWidth > 1240 && (
            <>
              <Text variant="BodyRegular">{_`vs.`}</Text>
              <Select
                leftIcon="Calendar"
                placeholder={`${moment(compareRange.start).format(
                  "MMM D, YY",
                )} - ${moment(compareRange.end).format("MMM D, YY")}`}
                options={[]}
                selected={[]}
                notOpenable={true}
                onClick={() => setDisplayDatePicker(true)}
              />
            </>
          )}
        </Grid>
      )}
    </>
  );
}
