import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  category: INTEGRATION_CATEGORIES.advertising,
  key: "bing-ads",
  image: "Bing",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.bingBg,
  borderColor: theme.customColors.bingBorder,
  label: "Bing Ads",
  adPlatformChannelName: "Bing Ads",
  utmGuide: "7061946",
  utmCode:
    "{lpurl}?utm_source=bing&campaign_id={CampaignId}&ad_id={AdId}&utm_medium=cpc&utm_campaign={Campaign}&utm_content={AdGroupId}&utm_term={AdGroup}&msclkid={msclkid}",
  get description() {
    return _`Online advertising platform from ${["provider", "Bing"]}`;
  },
  canReconnectOAuth: true,
  provider: "fivetran",
});
