import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "google-ads",
  category: INTEGRATION_CATEGORIES.advertising,
  image: "GoogleAds",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.googleFbInstaBg,
  borderColor: theme.customColors.googleFbInstaBorder,
  label: "Google Ads",
  adPlatformChannelName: "Google Ads",
  utmGuide: "6778939",
  utmCode:
    "{lpurl}?utm_source=google&campaign_id={campaignid}&ad_id={creative}&utm_medium=cpc&utm_campaign=actual_campaign_name_hardcoded&utm_content={adgroupid}&utm_term={placement}&gclid={gclid}",
  get description() {
    return _`Online advertising platform from ${["provider", "Google"]}`;
  },
  canReconnectOAuth: true,
  provider: "fivetran",
});
