import { TenantSettings } from "../../common/types/users-service";
import { BootstrapContextProps } from "../../hooks/bootstrap";
import { MetricDescription } from "../../types/synthesizer";

const SETTINGS_MAP_BY_METRIC: Record<
  string,
  Record<string, keyof TenantSettings>
> = {
  "shopify_sales_main.computed.total_sales": {
    "shopify_sales_main.raw.discounts": "revenue_computation_include_discounts",
    "shopify_sales_main.raw.returns": "revenue_computation_include_refunds",
    "shopify_sales_main.raw.shipping": "revenue_computation_include_shipping",
    "shopify_sales_main.raw.tax": "revenue_computation_include_tax",
    "shopify_sales_main.raw.tips": "revenue_computation_include_tip",
    "shopify_sales_main.raw.cost_of_products":
      "revenue_computation_deduct_cost_of_products",
    "shopify_sales_main.raw.expenses": "revenue_computation_deduct_expenses",
  },
  "shopify_sales_main.raw.total_orders": {},
};

type IsAffectedByDataSettingsPage = {
  metricKey: string;
  isMobile: boolean;
  description?: MetricDescription[];
};
export const isAffectedByDataSettingsPage = ({
  metricKey,
  isMobile,
  description,
}: IsAffectedByDataSettingsPage) => {
  return (
    !isMobile &&
    (description?.some((d) => d.isRevenueComputation || d.isOrderSettings) ||
      Object.keys(SETTINGS_MAP_BY_METRIC).includes(metricKey))
  );
};

export const getDisabledInfluences = (
  getUserTenantSetting: BootstrapContextProps["getUserTenantSetting"],
  metricKey: string,
) => {
  const settingsMap = SETTINGS_MAP_BY_METRIC[metricKey];

  return settingsMap
    ? Object.fromEntries(
        Object.entries(settingsMap)
          .filter(([, settingKey]) => getUserTenantSetting(settingKey, 0) !== 1)
          .map(([metricKey]) => [metricKey, true]),
      )
    : null;
};

export const METRIC_DEFINITIONS: Record<string, MetricDescription[]> = {
  "shopify_sales_main.computed.total_sales": [{ isRevenueComputation: true }],
};
