import { TenantSettings } from "../../common/types/users-service";
import { useBootstrap } from "../../hooks/bootstrap";
import { useFormatters } from "../../hooks/useFormatters";
import { MATH_OPERATORS, MetricDescription } from "../../types/synthesizer";
import {
  getTooltipElements,
  getTooltipOrderSettingsElements,
} from "../../utils/chartUtils";

type DescriptionSection = {
  elements: MetricDescription[];
  label?: string;
  type?: "list" | "formula";
};

export const useParsedDescription = (description: MetricDescription[]) => {
  const { getUserTenantSetting } = useBootstrap();
  const { currencyFormatter } = useFormatters();

  const parsedDescription = description
    .map((e) =>
      e.isOrderSettings
        ? getTooltipOrderSettingsElements(
            getUserTenantSetting,
            currencyFormatter,
          )
        : e.isRevenueComputation
        ? getTooltipElements(getUserTenantSetting, e.revenueComputationOptions)
        : ({
            ...e,
            disabled:
              e.disabled ||
              !!(
                e.setting &&
                getUserTenantSetting(e.setting as keyof TenantSettings, 0) !== 1
              ),
          } as MetricDescription),
    )
    .flat();

  const sections: DescriptionSection[] = [{ type: "list", elements: [] }];

  for (const element of parsedDescription) {
    if (element.isSubHeading) {
      sections.push({ type: "list", label: element.label, elements: [] });
      continue;
    }

    const section = sections[sections.length - 1];
    if (
      MATH_OPERATORS.includes(element.operator || "") ||
      MATH_OPERATORS.includes(element.label || "")
    ) {
      section.type = "formula";
    }
    section.elements.push(element);
  }

  return sections.map((s) => ({
    ...s,
    elements: s.elements.sort((e1, e2) => {
      if (e1.fixed || e2.fixed) {
        return 0;
      }

      if (e1.disabled && !e2.disabled) {
        return 1;
      }

      if (!e1.disabled && e2.disabled) {
        return -1;
      }

      return 0;
    }),
  }));
};
