import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "google-sheets",
  category: INTEGRATION_CATEGORIES.other_datasources,
  image: "GoogleSheets",
  dependsOnAccounts: false,
  backgroundColor: theme.customColors.googleFbInstaBg,
  borderColor: theme.customColors.googleFbInstaBorder,
  get label() {
    return _`Google Sheets`;
  },
  get description() {
    return _`Connect spend, expenses, exchange rates, COGs or any other data.`;
  },

  views: [
    {
      key: "google-sheets",
      get label() {
        return _`Google Sheets (Spend)`;
      },
    },
    {
      key: "google-sheets-custom",
      dependency: "google-sheets",
      get label() {
        return _`Google Sheets (Custom)`;
      },
    },
  ],
  canReconnectOAuth: true,
  provider: "fivetran",
});
