import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "klaviyo",
  category: INTEGRATION_CATEGORIES.marketing,
  image: "Klaviyo",
  dependsOnAccounts: false,
  backgroundColor: theme.customColors.klaviyoBg,
  borderColor: theme.customColors.klaviyoBorder,
  label: "Klaviyo",
  get description() {
    return _`E-mail service that lets you deliver amazing experiences`;
  },
  adPlatformChannelName: "Klaviyo",
  utmGuide: "6868158",
  provider: "fivetran",
});
