import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "gorgias",
  image: "Gorgias",
  label: "Gorgias",
  category: INTEGRATION_CATEGORIES.other_datasources,
  isNewIntegrationService: true,
  dependsOnAccounts: false,
  get description() {
    return _``;
  },
  get customConnectorInfo() {
    return [
      _`Due to API restrictions, Gorgias data corresponds the platform’s old statistics overview`,
    ];
  },
  backgroundColor: theme.customColors.gorgiasBg,
  borderColor: theme.customColors.gorgiasBorder,
  provider: "fivetran",
});
