import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "mailchimp",
  image: "Mailchimp",
  label: "Mailchimp",
  get description() {
    return _``;
  },
  category: INTEGRATION_CATEGORIES.marketing,
  isCustomConnector: true,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  provider: "fivetran",
});
