import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
  ReactElement,
  ReactNode,
} from "react";

import {
  AttributionRateData,
  RefreshStatusData,
  UtmCoverageItem,
  getShopifyPixelAttributionRates,
  getShopifyPixelStatus,
  getShopifyUtmCoverage,
  getPixelExtensionStatuses,
} from "../lib/integrationsService";
import {
  getLowAttributionByStore,
  isAvailableData,
} from "../pages/connectors/popups/connect/polarPixel/attributionRateUtils";
import { isFreeOrTrialPlan } from "../utils/subscriptionsUtils";
import { ATTRIBUTION_PIXEL_MODEL, removeDuplicates } from "../utils/utils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";
import { useConnectorObservability } from "./connectorObservability";

export enum PixelStatus {
  NoPixel = "noPixel",
  Loading = "loading",
  MissingUtmSetup = "missingUtmSetup",
  AttributionRateProblems = "attributionRateProblems",
  Live = "live",
}
type PixelStatusItem = {
  refreshPixelStatuses: () => Promise<void>;
  pixelStatusesByShop: Map<
    string,
    { id: string; isInstalled: boolean; hasPixelPermissions: boolean }
  >;
  asAtLeastOnePixelInstalled: boolean;
  isLoadingShops: boolean;
  isLoadingCoverage: boolean;
  isLoadingUtms: boolean;
  isLoadingExtensionStatuses: boolean;
  attributionRates: AttributionRateData[] | "loading" | "error";
  utmCoverage: UtmCoverageItem[];
  attributionRateSummary: {
    numberOfAccountsWithoutUtms: number;
    numberOfStoresWithLowAttributionRate: number;
  };
  status: PixelStatus;
  extensionStatuses: Map<
    string,
    {
      id: string;
      hasPixelPermissions: boolean;
      pixelConfig: undefined | Record<string, unknown>;
    }
  >;
};

interface ComputeConnectorStatusProps {
  isPixelInstalled: boolean;
  isLoadingCoverage: boolean;
  isLoadingUtms: boolean;
  utmCoverage: UtmCoverageItem[];
  numberOfAccountsWithoutUtms: number;
  numberOfStoresWithLowAttributionRate: number;
}

const computeConnectorStatus = ({
  isPixelInstalled,
  isLoadingCoverage,
  isLoadingUtms,
  utmCoverage,
  numberOfAccountsWithoutUtms,
  numberOfStoresWithLowAttributionRate,
}: ComputeConnectorStatusProps) => {
  if (isLoadingCoverage || isLoadingUtms) {
    return PixelStatus.Loading;
  }

  if (!isPixelInstalled) {
    return PixelStatus.NoPixel;
  }

  if (
    utmCoverage.length > 0 &&
    utmCoverage.every((item: UtmCoverageItem) => item.matched === "False")
  ) {
    return PixelStatus.MissingUtmSetup;
  }

  if (numberOfAccountsWithoutUtms + numberOfStoresWithLowAttributionRate > 0) {
    return PixelStatus.AttributionRateProblems;
  }
};

const isPixelSetUpCheck = (statuses: RefreshStatusData[]) => {
  const models = removeDuplicates(
    statuses.map((status) => status.models).flat(),
  );
  return models.includes(ATTRIBUTION_PIXEL_MODEL);
};

export const useCanSeePixel = () => {
  const { isDemoData, subscription, tenant } = useBootstrap();
  const { statuses } = useConnectorObservability();
  const isFreeOrTrial = isFreeOrTrialPlan(subscription);
  const isPixelSetUp = isPixelSetUpCheck(statuses);

  const canSeePixelSetup =
    (!isFreeOrTrial && !isDemoData) ||
    tenant.settings.onboarding_form?.responses?.[0].selection?.[0] ===
      "activate";
  const canSeePixelMetrics = (canSeePixelSetup && isPixelSetUp) || isDemoData;
  const canSeePixelStatus = canSeePixelSetup && isPixelSetUp;
  const canSeePixelInstall =
    subscription?.add_ons.includes("polar_pixel") ||
    subscription?.plan === 7 ||
    subscription?.plan === 6;
  const canSeePixelView = canSeePixelSetup && isPixelSetUp;
  const pixelConnectorStatus =
    canSeePixelSetup && isPixelSetUp ? ("ready" as const) : ("empty" as const);

  return {
    canSeePixelSetup,
    canSeePixelInstall,
    canSeePixelView,
    canSeePixelMetrics,
    canSeePixelStatus,
    pixelConnectorStatus,
  };
};

const usePixelStatus = (): PixelStatusItem => {
  const auth = useAuth();
  const { statuses } = useConnectorObservability();
  const [attributionRates, setAttributionRates] = useState<
    AttributionRateData[] | "loading" | "error"
  >("loading");
  const [utmCoverage, setUtmCoverage] = useState<UtmCoverageItem[]>([]);
  const [isLoadingCoverage, setIsLoadingCoverage] = useState(true);
  const [isLoadingUtms, setIsLoadingUtms] = useState(true);
  const [isLoadingShops, setIsLoadingShops] = useState(true);
  const [isLoadingExtensionStatuses, setIsLoadingExtensionStatuses] =
    useState(true);

  const [pixelStatusesByShop, setPixelStatusesByShop] = useState<
    Map<
      string,
      { id: string; isInstalled: boolean; hasPixelPermissions: boolean }
    >
  >(new Map());
  const [extensionStatuses, setExtensionStatuses] = useState<
    Map<
      string,
      {
        id: string;
        hasPixelPermissions: boolean;
        pixelConfig: undefined | Record<string, unknown>;
      }
    >
  >(new Map());
  const isPixelInstalled = isPixelSetUpCheck(statuses);

  const fetchUTMs = useCallback(
    async (abortController: AbortController) => {
      setIsLoadingUtms(true);
      const data = await getShopifyUtmCoverage(
        abortController,
        await auth.getToken(),
      );
      setUtmCoverage(data ?? []);
      setIsLoadingUtms(false);
      return () => {
        abortController.abort();
      };
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.processing, auth.isLoggedIn],
  );

  const fetchAttributionRates = useCallback(
    async (abortController?: AbortController) => {
      setIsLoadingCoverage(true);
      const data = await getShopifyPixelAttributionRates(
        abortController,
        await auth.getToken(),
      );
      setAttributionRates(data ?? "error");
      setIsLoadingCoverage(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.processing, auth.isLoggedIn],
  );

  const fetchExtensionStatuses = useCallback(
    async (abortController?: AbortController) => {
      setIsLoadingExtensionStatuses(true);
      const pixelExtensionStatuses = await getPixelExtensionStatuses(
        await auth.getToken(),
        abortController,
      );
      if (pixelExtensionStatuses) {
        setExtensionStatuses(
          new Map(
            pixelExtensionStatuses.map(
              (d) =>
                [d.shopify_url, d] as [
                  string,
                  {
                    id: string;
                    hasPixelPermissions: boolean;
                    pixelConfig: undefined | Record<string, unknown>;
                  },
                ],
            ),
          ),
        );
      }
      setIsLoadingExtensionStatuses(false);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.processing, auth.isLoggedIn],
  );

  const fetchConnectors = useCallback(
    async (abortController?: AbortController) => {
      if (auth.processing || !auth.isLoggedIn) {
        return;
      }
      setIsLoadingShops(true);
      setPixelStatusesByShop(new Map());
      const pixelInstallStatuses = await getShopifyPixelStatus(
        abortController,
        await auth.getToken(),
      );
      if (pixelInstallStatuses) {
        setPixelStatusesByShop(
          new Map(
            pixelInstallStatuses.map(
              (d) =>
                [d.storeUrl, d] as [
                  string,
                  {
                    id: string;
                    isInstalled: boolean;
                    hasPixelPermissions: boolean;
                  },
                ],
            ),
          ),
        );
        setIsLoadingShops(false);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [auth.processing, auth.isLoggedIn],
  );

  const refreshPixelStatuses = async () => {
    const abortController = new AbortController();
    await fetchConnectors(abortController);
  };

  useEffect(() => {
    const abortController = new AbortController();
    if (isPixelInstalled) {
      void fetchUTMs(abortController);
      void fetchAttributionRates(abortController);
      void fetchExtensionStatuses(abortController);
    } else {
      setIsLoadingCoverage(false);
      setIsLoadingUtms(false);
      setIsLoadingExtensionStatuses(false);
    }
    void fetchConnectors(abortController);
    return () => {
      abortController.abort();
    };
  }, [
    fetchUTMs,
    fetchAttributionRates,
    fetchConnectors,
    fetchExtensionStatuses,
    isPixelInstalled,
  ]);

  const accountsWithoutUtm = utmCoverage
    .filter((item) => item.matched === "False")
    .map(({ split_account_id }) => split_account_id);

  const numberOfAccountsWithoutUtms = Array.from(
    new Set(accountsWithoutUtm),
  ).length;

  const lowAttributionByStore = isAvailableData(attributionRates)
    ? getLowAttributionByStore(attributionRates)
    : [];

  const lowAttributionRateStores = lowAttributionByStore.filter(
    (item) => item.hasLowAttributionRate,
  );

  const numberOfStoresWithLowAttributionRate = lowAttributionRateStores.reduce(
    (previous, current) => {
      if (pixelStatusesByShop.get(current.store)) {
        return previous + 1;
      }
      return previous;
    },
    0,
  );

  const attributionRateSummary = {
    numberOfAccountsWithoutUtms,
    numberOfStoresWithLowAttributionRate,
  };

  const status = computeConnectorStatus({
    isPixelInstalled,
    isLoadingCoverage,
    isLoadingUtms,
    utmCoverage,
    numberOfAccountsWithoutUtms,
    numberOfStoresWithLowAttributionRate,
  });

  return {
    refreshPixelStatuses,
    pixelStatusesByShop,
    extensionStatuses,
    isLoadingShops,
    isLoadingCoverage,
    isLoadingUtms,
    isLoadingExtensionStatuses,
    attributionRates,
    utmCoverage,
    attributionRateSummary,
    asAtLeastOnePixelInstalled: Array.from(pixelStatusesByShop.values()).some(
      (v) => v.isInstalled,
    ),
    status: status ?? PixelStatus.Loading,
  };
};
const PixelConfigurationContext = createContext<PixelStatusItem | null>(null);

const useProvidePixelConfiguration = (): PixelStatusItem => {
  const pixelStatus = usePixelStatus();
  return {
    ...pixelStatus,
  };
};

const ProvidePixelConfigurationFetch = ({
  children,
}: {
  children: ReactNode;
}) => {
  const value = useProvidePixelConfiguration();

  return (
    <PixelConfigurationContext.Provider value={value}>
      {children}
    </PixelConfigurationContext.Provider>
  );
};

export const ProvidePixelConfiguration = ({
  children,
}: {
  children: ReactElement;
}) => {
  return (
    <ProvidePixelConfigurationFetch>{children}</ProvidePixelConfigurationFetch>
  );
};

export const usePixelConfiguration = () => {
  const context = useContext(PixelConfigurationContext);
  if (context === null) {
    throw Error("Pixel configuration context not provided");
  }
  return context;
};
