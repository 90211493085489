import { useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";
import styled from "styled-components";

import { useAuth, withAuthentication } from "../../hooks/auth/auth";
import {
  CREATION_CUSTOM_METRIC_ID,
  EMPTY_STATE_CUSTOM_METRIC_ID,
  useCustomElementEditors,
} from "../../hooks/customElementEditors";
import { useMetricList } from "../../hooks/metricList";
import { useModals } from "../../hooks/modals";
import { ConfirmPopup, Grid, Flex, Icon } from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { parseDateInCustomMetric } from "../../lib/customMetricService";
import { CustomMetricDefinition } from "../../types/synthesizer";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";

import "./create-custom-metrics.css";
import CentralForm from "./metric-components/CentralForm";
import CustomMetricFooter from "./metric-components/CustomMetricFooter";
import { getMetricFormulaError } from "./shared/utils";

const CustomMetricCloser = styled.div`
  z-index: 491;
  position: fixed;
  top: 8px;
  right: 8px;
  color: white;
  animation: animation-appear 0.2s ease-in-out;
`;

const hasNestedCustomMetric = (metric?: CustomMetricDefinition) =>
  metric?.elements?.some((element) => element.value.indexOf("custom_") > -1) ??
  false;

function CreateCustomMetrics() {
  const {
    editingMetrics,
    openedMetricEditor,
    discardMetricChanges,
    recordMetricChanges,
    hideMetric,
    saveCustomMetric,
  } = useCustomElementEditors();
  // "" for empty state, "0" for creation
  const id = openedMetricEditor || EMPTY_STATE_CUSTOM_METRIC_ID;
  // When metric is undefined, it represents for empty state
  const metric = editingMetrics.find(
    (m) => m.id.toString() === openedMetricEditor,
  );
  const auth = useAuth();
  const { loading: loadingMetrics, metrics: metricList } = useMetricList();
  const { showLoader, hideLoader, input, alert } = useModals();

  const [error, setError] = useState("");
  const [canLeave, setCanLeave] = useState(false);
  const [loading, setLoading] = useState(false);

  const blocker = useBlocker(() => canLeave);
  const handleRenameAndSave = () => {
    input({
      title: _`Your metric has no name`,
      texts: [_`Name your metric so you can save it`],
      inputPlaceholder: _`Name your metric`,
      onConfirm: async (title: string | number) => {
        if (!metric || !id) {
          return;
        }
        showLoader();
        recordMetricChanges(id, { ...metric, title: title as string });
        await handleMetricSave({ ...metric, title: title as string });
        hideLoader();
      },
    });
  };

  const handleMetricSave = async (metric?: CustomMetricDefinition) => {
    if (!metric) {
      return;
    }
    if (metric.elements.length === 0) {
      alert({
        danger: true,
        title: _`Warning`,
        texts: [_`You must add at least one element.`],
      });
      return;
    }
    const formulaError = getMetricFormulaError(metric.elements);
    if (formulaError !== "") {
      setError(formulaError);
      return;
    }

    if (metric.title === "") {
      handleRenameAndSave();
      return;
    }

    showLoader();
    setLoading(true);
    await saveCustomMetric(() => {
      setCanLeave(false);
      setLoading(false);
    }, metric);

    trackEvent(TRACKING_EVENTS.CUSTOM_METRIC_SAVE, {
      hasNestedCustomMetric: hasNestedCustomMetric(metric),
    });
    setCanLeave(true);
    setLoading(false);
    hideLoader();
  };
  useEffect(() => {
    if (!auth.processing) {
      if (id !== EMPTY_STATE_CUSTOM_METRIC_ID) {
        if (id !== CREATION_CUSTOM_METRIC_ID) {
          trackEvent(TRACKING_EVENTS.CUSTOM_METRIC_EDIT, {
            hasNestedCustomMetric: hasNestedCustomMetric(metric),
          });
        } else {
          trackEvent(TRACKING_EVENTS.CUSTOM_METRIC_CREATE, {});
        }
      }
    }
  }, [id, metric, auth.processing]);

  useEffect(() => {
    setError("");
  }, [metric]);

  return (
    <>
      <div className="custom-metric-shadow" />
      <CustomMetricCloser>
        <Flex gap={8} alignItems={"center"}>
          <Icon name={"CloseCircle"} onClick={hideMetric} size={24} />
        </Flex>
      </CustomMetricCloser>
      <div className="custom-metric-window">
        {blocker.state === "blocked" ? (
          <ConfirmPopup
            onConfirm={() => blocker.proceed()}
            onDecline={() => blocker.reset()}
            confirmLabel={_`Abandon changes`}
            declineLabel={_`Keep editing`}
          >
            {_`Are you sure you want to leave the custom metric builder?`}
          </ConfirmPopup>
        ) : null}

        <Grid
          gridTemplateRows="auto 56px"
          gap={1}
          className="create-custom-metric"
        >
          <CentralForm
            metric={metric}
            metricList={metricList}
            onMetricChange={(m) => {
              recordMetricChanges(id, parseDateInCustomMetric(m));
            }}
            error={error !== "" ? error : undefined}
            loading={loading || loadingMetrics}
          />

          <CustomMetricFooter
            loading={loading}
            onMetricDiscardChanges={() => {
              discardMetricChanges(id);
            }}
            onMetricSave={async () => {
              await handleMetricSave(metric);
            }}
            onClose={hideMetric}
            editing={
              id !== EMPTY_STATE_CUSTOM_METRIC_ID &&
              id !== CREATION_CUSTOM_METRIC_ID
            }
            empty={id === EMPTY_STATE_CUSTOM_METRIC_ID}
          />
        </Grid>
      </div>
    </>
  );
}

export default withAuthentication(CreateCustomMetrics);
