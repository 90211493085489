import classnames from "classnames";
import { Suspense } from "react";
import styled, { FlattenInterpolation } from "styled-components";

import { Box } from "../Box";

import { IconSVG } from "./svgs";
import { IconSVGProps } from "./types";

export type IconNames =
  | "ActivateDollar"
  | "ActivateEnvelope"
  | "ActivateTrophy"
  | "ActivateUser"
  | "Add"
  | "AddMarker"
  | "AddMember"
  | "AddMessage"
  | "Addons"
  | "AskPolar"
  | "Cards"
  | "CaretBack"
  | "CaretNext"
  | "Capi"
  | "Columns"
  | "ArrowDown"
  | "ArrowDownWithTail"
  | "ArrowLeft"
  | "ArrowLeftAlign"
  | "ArrowRight"
  | "ArrowRightAlign"
  | "ArrowNumber"
  | "ArrowUp"
  | "ArrowUpWithTail"
  | "BarChart"
  | "LineChart"
  | "PieChart"
  | "Bell"
  | "Clock"
  | "Calendar"
  | "Check"
  | "Clipboard"
  | "Close"
  | "CloseCircle"
  | "ColoredGift"
  | "Connection"
  | "Delete"
  | "Dislike"
  | "Documentation"
  | "Dragger"
  | "Download"
  | "Dropdown"
  | "Duplicate"
  | "Earth"
  | "Edit"
  | "Enter"
  | "EyeOpen"
  | "EyeClosed"
  | "EvolutionDown"
  | "EvolutionUp"
  | "Exchange"
  | "Exit"
  | "ExternalLink"
  | "Gift"
  | "FlowChart"
  | "Folder"
  | "FolderLine"
  | "Filters"
  | "Footprint"
  | "Group"
  | "Guests"
  | "Help"
  | "Indicator"
  | "InfoCircle"
  | "Letters"
  | "Level2"
  | "Level3"
  | "Journey"
  | "Link"
  | "Lock"
  | "Loading"
  | "Unlock"
  | "Like"
  | "Logout"
  | "More"
  | "MenuFold"
  | "MenuUnfold"
  | "MinusOperator"
  | "Numbers"
  | "Paint"
  | "Percent"
  | "Picture"
  | "Pin"
  | "Pivot"
  | "PointingArrow"
  | "PlusOperator"
  | "Question"
  | "Refresh"
  | "Reload"
  | "RoundedCheck"
  | "Search"
  | "Send"
  | "Settings"
  | "SettingsFilled"
  | "ShoppingBag"
  | "Sparkle"
  | "SuggestAFeature"
  | "Time"
  | "Table"
  | "Tabulation"
  | "Target"
  | "Top"
  | "TopFilled"
  | "Triangle"
  | "TryAddon"
  | "VectorDown"
  | "VectorRight"
  | "Verified"
  | "VerifiedFilled"
  | "Warning"
  | "FirstTouchModel"
  | "FullImpactModel"
  | "FullPaidImpactModel"
  | "LastTouchModel"
  | "LinearModel"
  | "LinearPaidModel"
  | "UShapedModel"
  | "TimeDecayModel"
  | "DateRangeLock"
  | "DateRangeWarning"
  | "Granularity"
  | "GranularityLock"
  | "GranularityWarning";

export type IconProps = IconSVGProps & { name: IconNames };

function Icon({
  name,
  size = 16,
  color,
  className,
  onClick,
  ...props
}: Omit<IconProps, "size"> & { size?: number }) {
  if (IconSVG[name] === undefined) {
    console.warn("Icon not found", name);
    return null;
  }

  const SVGComponent = IconSVG[name];

  return (
    <Suspense
      fallback={
        <Box display="inline-block" width={size} height={size}>
          &nbsp;
        </Box>
      }
    >
      <SVGComponent
        className={classnames(className, { pointer: !!onClick })}
        onClick={onClick}
        size={size}
        {...(color ? { color } : {})}
        {...props}
      />
    </Suspense>
  );
}

export default styled(Icon)<{ cssStyles?: FlattenInterpolation<unknown> }>`
  ${(props) => props.cssStyles}
`;
