import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { ConnectorConfigureOnlyButton } from "../pages/connectors/popups/edit/ConnectorConfigureOnlyButton";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "google-analytics",
  category: INTEGRATION_CATEGORIES.analytics_and_attribution,
  image: "GoogleAnalytics",
  dependsOnAccounts: true,
  isRetired: true,
  backgroundColor: theme.customColors.googleFbInstaBg,
  borderColor: theme.customColors.googleFbInstaBorder,
  label: "Google Universal Analytics",
  get description() {
    return _`Tracking system and Analytics platform`;
  },
  canReconnectOAuth: true,
  getButtonSection: () => ConnectorConfigureOnlyButton,
  provider: "fivetran",
});
