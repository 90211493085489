import { useState, useCallback, useRef, useMemo } from "react";

import {
  ConnectorStatusData,
  RefreshStatusData,
  getConnectorsStatuses,
} from "../lib/integrationsService";

import { useAuth } from "./auth/auth";
import { useConnectors } from "./useConnectorData";
import { useInterval } from "./useInterval";

const getDefaultStatus = (datasourceId: string) =>
  ({
    datasource_id: datasourceId,
    setup_state: "building",
    updated_at: null,
  }) as RefreshStatusData;

export const serializeStatusData = (
  connectorData: ConnectorStatusData,
): RefreshStatusData => {
  return {
    is_historical_sync: connectorData.platform.is_historical_sync,
    is_enabled: connectorData.polar.is_enabled,
    last_refresh_date: connectorData.polar.last_processed_at,
    next_refresh_date: connectorData.platform?.next_sync_start_at,
    timezone: connectorData.polar?.timezone,
    setup_state: connectorData.status?.global,
    sync_state: connectorData.status?.sync,
    updated_at: connectorData.updated_at ?? null,
    datasource_id: connectorData.datasource_id,
    models: connectorData.polar.models ?? [],
    integration: connectorData.integration,
    errors: connectorData.errors,
    oauthAccounts: connectorData?.oauth?.accounts ?? [],
    polarConnectorAccounts: connectorData?.polar?.accounts ?? [],
    polarConnectorSubAccounts: connectorData?.polar?.subaccounts ?? [],
    polarConnectorTimezone: connectorData?.polar?.timezone,
  };
};

const isFirstStatusNewer = (
  firstStatus: RefreshStatusData | null,
  secondStatus: RefreshStatusData | null,
) => {
  if (!firstStatus) {
    return false;
  }
  if (!secondStatus) {
    return true;
  }
  if (firstStatus.updated_at === null) {
    return false;
  }
  if (secondStatus.updated_at === null) {
    return true;
  }
  if (firstStatus.updated_at > secondStatus.updated_at) {
    return true;
  }
  return false;
};

const FETCH_LIMIT = 12;

export const useConnectorStatuses = () => {
  const auth = useAuth();
  const fetchCount = useRef(0);
  const { data: connectorData, isFetched, isFetching } = useConnectors();
  const [loaded, setLoaded] = useState(false);
  const [statuses, setStatuses] = useState<RefreshStatusData[]>([]);
  const latestPreviousStatuses = useRef<Map<string, RefreshStatusData | null>>(
    new Map(),
  );
  const isWaitingForStatuses = useRef<Map<string, boolean | undefined>>(
    new Map(),
  );

  const setIsWaitingForStatus = useCallback(
    (datasourceId: string) => {
      isWaitingForStatuses.current.set(datasourceId, true);
    },
    [isWaitingForStatuses],
  );

  const setStatusesIfDifferent = useCallback(
    (
      handler: (previousStatuses: RefreshStatusData[]) => RefreshStatusData[],
    ) => {
      setStatuses((previousStatuses) => {
        const statuses = handler(previousStatuses);
        if (JSON.stringify(previousStatuses) === JSON.stringify(statuses)) {
          return previousStatuses;
        } else {
          return statuses;
        }
      });
    },
    [],
  );

  const setStatusForDatasource = useCallback(
    (datasourceId: string, status: RefreshStatusData) => {
      fetchCount.current = 0;
      setStatusesIfDifferent((statuses) =>
        statuses
          .sort((a, b) => a.datasource_id.localeCompare(b.datasource_id))
          .map((s) => (s.datasource_id === datasourceId ? status : s)),
      );
    },
    [setStatusesIfDifferent],
  );

  const fetchConnectorStatuses = useCallback(async () => {
    if (fetchCount.current < FETCH_LIMIT) {
      const connectorsData = await getConnectorsStatuses(await auth.getToken());
      if (connectorsData === null) {
        return;
      }
      const serializedStatuses = connectorsData
        .sort((a, b) => a.datasource_id.localeCompare(b.datasource_id))
        .map(serializeStatusData);
      setStatusesIfDifferent(() => serializedStatuses);
      fetchCount.current = fetchCount.current + 1;
      serializedStatuses.forEach((serializedStatus) => {
        const { datasource_id: datasourceId } = serializedStatus;
        const latestValue =
          latestPreviousStatuses.current.get(datasourceId) ?? null;
        latestPreviousStatuses.current.set(
          datasourceId,
          isFirstStatusNewer(serializedStatus, latestValue)
            ? serializedStatus
            : latestValue,
        );
      });
      setLoaded(true);
    }
  }, [auth, setStatusesIfDifferent]);

  useInterval(() => void fetchConnectorStatuses(), 15000);

  let statusesToReturn: RefreshStatusData[] = [];

  statuses.forEach((status) => {
    const { datasource_id: datasourceId } = status;
    const isWaitingForNewStatus =
      isWaitingForStatuses.current.get(datasourceId) ?? false;
    const latestValue =
      latestPreviousStatuses.current.get(datasourceId) ?? null;
    if (isWaitingForNewStatus) {
      if (status && latestValue && isFirstStatusNewer(status, latestValue)) {
        statusesToReturn = [...statusesToReturn, status];
        isWaitingForStatuses.current.set(datasourceId, false);
      } else {
        statusesToReturn = [
          ...statusesToReturn,
          getDefaultStatus(datasourceId),
        ];
      }
    } else {
      // This is needed because the status fetch without oauth refresh can return outdated status because of cache clearing delay.
      if (latestValue) {
        statusesToReturn = [
          ...statusesToReturn,
          isFirstStatusNewer(status, latestValue) ? status : latestValue,
        ];
      } else {
        statusesToReturn = [...statusesToReturn, status];
      }
    }
  });
  const connectorsWithErrors = statuses.filter(
    (status) => !!status.is_enabled && status.setup_state === "broken",
  );
  const { hasShopifyHistoricalSync, hasLongShopifyHistoricalSync } =
    useMemo(() => {
      const integration = "shopify";
      const threshold = 2 * 24 * 60 * 60 * 1000;

      if (!isFetched) {
        return {
          hasShopifyHistoricalSync: false,
          hasLongShopifyHistoricalSync: false,
        };
      }
      const shopifyAllHistoricalSyncDatasourceIds = statuses
        .filter(
          (status) =>
            status.is_historical_sync &&
            status.integration === integration &&
            !!status.is_enabled,
        )
        .map((status) => status.datasource_id);

      const now = new Date().getTime();
      // sync longer than 2 days
      const hasLongShopifyHistoricalSync =
        connectorData?.[integration]?.filter(
          (connector) =>
            shopifyAllHistoricalSyncDatasourceIds.includes(connector.id) &&
            new Date(connector.created_at).getTime() < now - threshold,
        ) ?? [];
      return {
        hasShopifyHistoricalSync:
          shopifyAllHistoricalSyncDatasourceIds.length > 0,
        hasLongShopifyHistoricalSync: hasLongShopifyHistoricalSync.length > 0,
      };
      // here we need to include "isFetching" because we need it to trigger the hook's re-render
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuses, connectorData, isFetched, isFetching]);

  return {
    statuses: statusesToReturn,
    setIsWaitingForStatus,
    setStatusForDatasource,
    connectorsWithErrors,
    hasShopifyHistoricalSync,
    hasLongShopifyHistoricalSync,
    loaded,
  };
};
