import moment from "moment";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { NotificationManager } from "react-notifications";
import uniqid from "uniqid";

import { useAuth } from "../../../hooks/auth/auth";
import { useSmartFilter } from "../../../hooks/useSmartFilter";
import {
  ConnectorAccounts,
  GenericDataSourceConfiguration,
  getDefaultViewsAccounts,
  getIntegrationDefaultView,
  getIntegrationHandler,
  getIntegrationIcon,
  getViewsList,
  withNewIntegrationService,
} from "../../../integrations/integration";
import { _ } from "../../../languages/helper";
import {
  AmazonAccount,
  AmazonDataSourceConfiguration,
  BingDataSourceConfiguration,
  BingDataSourceData,
  ConnectorsOfDatasource,
  FbDataSourceConfiguration,
  FbDataSourceData,
  GADataSourceConfiguration,
  GADataSourceData,
  GAFourDataSourceConfiguration,
  GAFourDataSourceData,
  GAdsDataSourceConfiguration,
  GAdsDataSourceData,
  GoogleSheetsDataSourceData,
  KlaviyoDataSourceConfiguration,
  ShopifyDataSourceConfiguration,
  SnapChatDataSourceConfiguration,
  SnapChatDataSourceData,
  TikTokDataSourceConfiguration,
  TikTokDataSourceData,
  getConnectorDetails,
  getConnectorsOfDataSource,
  getDatasourcesV2,
} from "../../../lib/integrationsService";
import { compose, provideDimensions } from "../../../lib/synthService";
import {
  CustomView,
  CustomViewConnector,
  CustomViewGroup,
  CustomViewRule,
  CustomViewStore,
} from "../../../lib/viewsService";
import { IDimension, ITable } from "../../../types/synthesizer";
import { keyToLabel } from "../../../utils/utils";

import {
  VIEW_KEY_TO_CONNECTOR_ICON_MAPPING,
  VIEW_KEY_TO_CONNECTOR_MAPPING,
} from "./viewMapping";

type MetricWithDataSourceId = {
  datasource_id: string;
  [key: string]: unknown;
};

let connectorAccounts: ConnectorAccounts = getDefaultViewsAccounts();

const setConnectorAccounts = (
  v: (c: ConnectorAccounts) => ConnectorAccounts,
) => {
  connectorAccounts = v(connectorAccounts);
};

const dataSourceConnectorsByKey: {
  [key: string]: (ConnectorsOfDatasource & {
    connectorKey: string;
  })[];
} = {};

const patchStoreTitle = (connectorKey: string) => (s: CustomViewStore) => {
  const dataSource = dataSourceConnectorsByKey[connectorKey].find(
    (d) => d.id === s.identifier,
  );

  const title =
    (dataSource &&
      getIntegrationHandler(connectorKey)?.getAccountTitle?.(
        dataSource as GenericDataSourceConfiguration,
      )) ??
    s.title;

  return {
    ...s,
    title: title,
  };
};

let connectorStoresStatus: {
  [key: string]: boolean;
} = {};
const setConnectorStoresStatus = (
  v: (c: { [key: string]: boolean }) => {
    [key: string]: boolean;
  },
) => {
  connectorStoresStatus = v(connectorStoresStatus);
};

export const resetViewEditor = () => {
  connectorAccounts = getDefaultViewsAccounts();
  connectorStoresStatus = {};
};

interface CountryFiltersRuleValueList {
  [connectorKey: string]: {
    [filterKey: string]: {
      [searchText: string]: {
        values: string[];
        loading: boolean;
      };
    };
  };
}

export interface ViewsContextProps {
  viewsBeingEdited: CustomView[];
  setViewsBeingEdited: (_views: CustomView[]) => void;
  viewBeingEdited: CustomView | undefined;
  viewIndexBeingEdited: number;
  setViewIndexBeingEdited: (index: number) => void;
  connectorAccounts: ConnectorAccounts;
  connectorStoresStatus: {
    [key: string]: boolean;
  };
  customViewGroups: CustomViewGroup[] | null;
  setCustomViewGroups: (
    viewGroups:
      | CustomViewGroup[]
      | null
      | ((viewGroups: CustomViewGroup[] | null) => CustomViewGroup[] | null),
  ) => void;
  selectedViews: CustomView[];
  doViewsCoverConnectors: (
    selectedViews: CustomView[],
    requirements: string[] | string[][],
    tableKeyToConnectorKey: { [key: string]: string[] },
  ) => boolean;
  doSelectedViewsCoverConnector: (
    connectors: string[] | string[][],
    tableKeyToConnectorKey: { [key: string]: string[] },
  ) => boolean;
  doSelectedViewsCoverAccountId: (accountId: string) => boolean;
  ruleValueLists: CountryFiltersRuleValueList;
  loadRuleValueList: (
    connectorKey: string,
    filterKey: string,
    table: string,
    searchText: string,
  ) => Promise<void>;
  updateCurrentlyEditedView: (view: CustomView) => void;
  addConnector: (id: string, connector: CustomViewConnector) => void;
  duplicateView: (id: string) => void;
  deleteView: (id: string) => void;
  toggleConnectorOpen: (viewId: string, connectorId: number) => void;
  toggleConnectorChildren: (viewId: string, connectorId: number) => void;
  changeConnectorType: (
    viewId: string,
    connectorId: number,
    category: string,
    key: string,
    table: ITable,
  ) => void;
  deleteViewConnector: (id: string, connectorId: number) => void;
  getViewConnectorStores: (
    id: string,
    connectorId: number,
    connectorKey: string,
    metricsTables?: { [key: string]: ITable },
  ) => Promise<void>;
  toggleConnectorStoreOpen: (
    id: string,
    connectorId: number,
    storeId: number,
  ) => void;
  toggleConnectorStoreCheck: (
    id: string,
    connectorId: number,
    storeId: number,
  ) => void;
  addViewConnectorStoreRule: (
    viewId: string,
    connectorId: number,
    storeId: number,
    store: CustomViewRule,
  ) => void;
  deleteViewConnectorStoreRule: (
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
  ) => void;
  editViewConnectorStoreRule: (
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
    rule: CustomViewRule,
  ) => void;
  editViewConnectorStoreRuleKey: <T extends keyof CustomViewRule>(
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
    key: T,
    value: CustomViewRule[T],
  ) => void;
}

export const ViewsContext = createContext<ViewsContextProps | null>(null);

export function ProvideViews({ children }: { children: ReactNode }) {
  const views = useProvideViews();
  return (
    <ViewsContext.Provider value={views}>{children}</ViewsContext.Provider>
  );
}

// isRuleInjectable checks if the rule definition is complete and the dimension is available
const isRuleInjectable = (
  rule: CustomViewRule,
  dimensions: { [key: string]: IDimension },
) => {
  return (
    rule.filterKey && rule.operator && rule.value && dimensions[rule.filterKey]
  );
};

export const useViews = (): ViewsContextProps => {
  const context = useContext(ViewsContext);
  if (context === null) {
    throw Error("Views context not provided");
  }
  return context;
};

function useProvideViews(): ViewsContextProps {
  const viewsConnectorList = getViewsList();
  const auth = useAuth();
  const { selectedViewIds } = useSmartFilter();

  const [customViewGroups, setCustomViewGroups] = useState<
    CustomViewGroup[] | null
  >(null);

  const [viewsBeingEdited, setViews] = useState<CustomView[]>([]);
  const [viewIndexBeingEdited, setViewIndexBeingEdited] = useState<number>(0);
  const viewBeingEdited = viewsBeingEdited[viewIndexBeingEdited];

  const [ruleValueLists, setRuleValueLists] =
    useState<CountryFiltersRuleValueList>({});

  const loadRuleValueList = async (
    connectorKey: string,
    filterKey: string,
    table: string,
    searchText = "",
  ) => {
    if (!filterKey) {
      return;
    }

    if (
      !ruleValueLists[connectorKey] ||
      !ruleValueLists[connectorKey][filterKey] ||
      !ruleValueLists[connectorKey][filterKey][searchText]
    ) {
      setRuleValueLists((v) => ({
        ...v,
        [connectorKey]: {
          ...v[connectorKey],
          [filterKey]: {
            ...(v[connectorKey] && v[connectorKey][filterKey]
              ? v[connectorKey][filterKey]
              : {}),
            [searchText]: {
              loading: true,
              values: [],
            },
          },
        },
      }));
      await provideDimensions(
        // if the dimension is not mentioned in a table, we try the local config in the frontend
        // otherwise, we use the first table mentioned in the list to fetch the value.
        // This way will fix the existing issue of not being able to fetch the values for dimensions that do not belong to the root table.
        // @Pascal I think it should be fine if dimension is available in multiple tables and we fetch it only from on table :)
        [table],
        filterKey,
        searchText,
      )(await auth.getToken()).then((data) => {
        setRuleValueLists((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            [filterKey]: {
              ...v[connectorKey][filterKey],
              [searchText]: {
                loading: false,
                values: data,
              },
            },
          },
        }));
      });
    }
  };

  const setViewsBeingEdited = (_views: CustomView[]) => {
    setViews(_views);
  };

  const updateCurrentlyEditedView: ViewsContextProps["updateCurrentlyEditedView"] =
    (view) => {
      setViews([
        ...viewsBeingEdited.slice(0, viewIndexBeingEdited),
        view,
        ...viewsBeingEdited.slice(viewIndexBeingEdited + 1),
      ]);
    };

  const addConnector = (id: string, connector: CustomViewConnector) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === id);
    viewsBeingEdited[index].connectors.push(connector);
    setViews([...viewsBeingEdited]);
  };

  const duplicateView = (id: string) => {
    const viewToCopy = viewsBeingEdited.find((v) => v.id === id);
    if (viewToCopy) {
      setViewIndexBeingEdited(viewsBeingEdited.length);
      setViews([
        ...viewsBeingEdited,
        {
          ...(structuredClone
            ? structuredClone(viewToCopy)
            : JSON.parse(JSON.stringify(viewToCopy))),
          title: viewToCopy.title + " (" + _`Copy|||noun` + ")",
          id: uniqid(),
        },
      ]);
    }
  };

  const deleteView = (id: string) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === id);
    viewsBeingEdited.splice(index, 1);
    setViewIndexBeingEdited((v) => Math.max(v - 1, 0));
    setViews([...viewsBeingEdited]);
  };

  const toggleConnectorOpen = (viewId: string, connectorId: number) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].opened =
      !viewsBeingEdited[index].connectors[connectorId].opened;
    setViews([...viewsBeingEdited]);
  };

  const toggleConnectorChildren = (viewId: string, connectorId: number) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    const current = viewsBeingEdited[index].connectors[connectorId];
    const checked = !!current.stores.find((s) => !s.checked);
    viewsBeingEdited[index].connectors[connectorId] = {
      connectorKey: current.connectorKey,
      name: current.name,
      icon: current.icon,
      opened: current.opened,
      stores: current.stores.map((s) => ({
        ...s,
        checked,
      })),
    };
    setViews([...viewsBeingEdited]);
  };

  const changeConnectorType = (
    viewId: string,
    connectorId: number,
    category: string,
    key: string,
    table: ITable,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    const connector = viewsConnectorList[category].find((c) => c.key === key);
    if (connector) {
      const current = viewsBeingEdited[index].connectors[connectorId];
      viewsBeingEdited[index].connectors[connectorId] = {
        connectorKey: key,
        name: connector.label,
        icon: getIntegrationIcon(
          VIEW_KEY_TO_CONNECTOR_ICON_MAPPING[key] ?? key,
        ),
        opened: current.opened,
        stores: connectorAccounts[key]
          ? [...connectorAccounts[key].accounts].map((s) => ({
              connectorKey: key,
              title: s.title,
              subtitle: s.subtitle,
              identifier: s.identifier,
              checked: true,
              opened: true,
              rules: [
                ...connectorAccounts[key].defaultRules.map((r) => ({ ...r })),
              ],
            }))
          : [],
      };
      if (key === "polar-pixel") {
        // when we pick polar-pixel, we need to add the default rules from the other connectors
        // as request by @Louise, it is frustrated to add the same rules for each connector, like fb ad, google ad, etc
        // the idea of pixel is to add additional info on top of all the other connector's we select,
        // so this code block will try to get all the filters in the view we added for other connectors to simplify a bit the creation
        const dimensions =
          table?.dimensions ?? ({} as { [key: string]: IDimension });
        // key is built by  combination of the following:
        //   filterKey: string;
        //   operator: string;
        //   value: string;
        const rulesFromOtherStores: { [x: string]: CustomViewRule } = {};

        Object.entries(viewsBeingEdited[index].connectors)
          .filter(([key, _]) => key !== connector.key)
          .forEach(([_, otherConnector]) => {
            otherConnector?.stores?.forEach((store) => {
              store.rules.forEach((rule) => {
                if (!isRuleInjectable(rule, dimensions)) {
                  return;
                }
                const key = `${rule.filterKey}|||${rule.operator}|||${rule.value}`;
                rulesFromOtherStores[key] = {
                  ...rule,
                };
              });
            });
          });
        const stores = viewsBeingEdited[index].connectors[connectorId].stores;
        viewsBeingEdited[index].connectors[connectorId].stores = stores.map(
          (store) => ({
            ...store,
            rules: [...Object.values(rulesFromOtherStores)],
          }),
        );
      }
      setViews([...viewsBeingEdited]);
    }
  };

  const deleteViewConnector = (id: string, connectorId: number) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === id);
    viewsBeingEdited[index].connectors.splice(connectorId, 1);
    setViews([...viewsBeingEdited]);
  };

  const getDatasourceIdsForConnector = async (
    metricsTables: {
      [key: string]: ITable;
    },
    connectorKey: string,
  ) => {
    const customConnector = Object.values(metricsTables ?? {}).find(
      ({ connectorKeys }) => connectorKeys?.includes(connectorKey),
    );
    const firstMetric = Object.values(customConnector?.metrics?.raw ?? {})?.[0]
      .key;
    const sampleDataWithDataSourceId: MetricWithDataSourceId[] = await compose(
      undefined,
      await auth.getToken(),
      [`${customConnector?.rootTableKey}.raw.${firstMetric}`],
      { start: moment().subtract(10, "year"), end: moment() },
      "year",
      ["datasource_id"],
      {},
      undefined,
      "desc",
      false,
    );
    return {
      uniqueDatasourceIds: new Set(
        sampleDataWithDataSourceId.map((item) => item?.datasource_id),
      ),
      connectorLabel: customConnector?.label,
    };
  };

  const getViewConnectorStores = async (
    id: string,
    connectorId: number,
    connectorKey: string,
    metricsTables?: { [key: string]: ITable },
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === id);
    if (connectorStoresStatus[connectorKey] !== undefined) {
      await new Promise((r) => {
        const test = () => {
          if (connectorStoresStatus[connectorKey]) {
            viewsBeingEdited[index].connectors[connectorId].stores =
              [...viewsBeingEdited[index].connectors[connectorId].stores].map(
                patchStoreTitle(connectorKey),
              ) || [];
            connectorAccounts[connectorKey]?.accounts.forEach((store) => {
              if (
                !viewsBeingEdited[index].connectors[connectorId].stores
                  .map((s) => s.identifier)
                  .includes(store.identifier)
              ) {
                viewsBeingEdited[index].connectors[connectorId].stores.push({
                  connectorKey,
                  title: store.title,
                  subtitle: store.subtitle,
                  identifier: store.identifier,
                  checked: false,
                  opened: true,
                  rules: [
                    ...connectorAccounts[connectorKey].defaultRules.map(
                      (r) => ({ ...r }),
                    ),
                  ],
                });
              }
            });
            setViews([...viewsBeingEdited]);

            r(null);
            return;
          }
          setTimeout(test, 500);
        };
        setTimeout(test, 500);
      });
      return;
    }

    setConnectorStoresStatus((s) => ({ ...s, [connectorKey]: false }));

    let dataSourceConnectors: (ConnectorsOfDatasource & {
      connectorKey: string;
    })[];
    const fixedConnectorKey =
      connectorKey === "google-sheets-custom" ? "google-sheets" : connectorKey;
    try {
      if (getIntegrationHandler(fixedConnectorKey)?.isCustomConnector) {
        dataSourceConnectors = [];
      } else {
        if (withNewIntegrationService(fixedConnectorKey)) {
          const datasourcesV2 = await getDatasourcesV2(
            await auth.getToken(),
            VIEW_KEY_TO_CONNECTOR_MAPPING[connectorKey] || connectorKey,
          );
          const newConnectors = datasourcesV2.map((d) => ({
            ...d,
            connectorKey,
          }));
          dataSourceConnectors = [...newConnectors];
        } else {
          const datasources = await getConnectorsOfDataSource(
            await auth.getToken(),
            VIEW_KEY_TO_CONNECTOR_MAPPING[connectorKey] || connectorKey,
          );
          dataSourceConnectors = datasources.map((d) => ({
            ...d,
            connectorKey,
          }));
        }
      }

      if (connectorKey === "google-analytics") {
        if (withNewIntegrationService("google-analytics-four")) {
          dataSourceConnectors.push(
            ...(
              await getDatasourcesV2(
                await auth.getToken(),
                VIEW_KEY_TO_CONNECTOR_MAPPING["google-analytics-four"] ||
                  "google-analytics-four",
              )
            ).map((d) => ({ ...d, connectorKey: "google-analytics-four" })),
          );
        } else {
          dataSourceConnectors.push(
            ...(
              await getConnectorsOfDataSource(
                await auth.getToken(),
                VIEW_KEY_TO_CONNECTOR_MAPPING["google-analytics-four"] ||
                  "google-analytics-four",
              )
            ).map((d) => ({ ...d, connectorKey: "google-analytics-four" })),
          );
        }
      }
    } catch (e) {
      console.error(e);
      dataSourceConnectors = [];
    }

    // This code is going to be removed once we have created the new datasources for custom connectors
    // on the datasource service
    if (getIntegrationHandler(fixedConnectorKey)?.isCustomConnector) {
      const { uniqueDatasourceIds, connectorLabel } =
        await getDatasourceIdsForConnector(metricsTables ?? {}, connectorKey);
      Array.from(uniqueDatasourceIds).forEach((datasourceId, index) =>
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Account` + ` ${index + 1}`,
                subtitle: connectorLabel || "",
                identifier: datasourceId,
              },
            ],
          },
        })),
      );
    }

    for (let i = 0; i < dataSourceConnectors.length; i++) {
      const sourceConnector = dataSourceConnectors[i];
      const { connectorKey } = sourceConnector;

      if (connectorKey === "shopify") {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Shop` + ` ${i + 1}`,
                subtitle: (
                  dataSourceConnectors[i] as ShopifyDataSourceConfiguration
                ).shopify_url,
                identifier: (
                  dataSourceConnectors[i] as ShopifyDataSourceConfiguration
                ).shopify_url,
              },
            ],
          },
        }));
      } else if (connectorKey === "polar-pixel") {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            connectorKey: VIEW_KEY_TO_CONNECTOR_MAPPING[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Shop` + ` ${i + 1}`,
                subtitle: (
                  dataSourceConnectors[i] as ShopifyDataSourceConfiguration
                ).shopify_url,
                identifier: (
                  dataSourceConnectors[i] as ShopifyDataSourceConfiguration
                ).shopify_url,
              },
            ],
          },
        }));
      } else if (connectorKey === "pinterest-ads") {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Account` + ` ${i + 1}`,
                subtitle: "Pinterest " + _`Account`,
                identifier: dataSourceConnectors[i].id,
              },
            ],
          },
        }));
      } else if (connectorKey === "google-sheets") {
        if (
          (dataSourceConnectors[i] as GoogleSheetsDataSourceData)
            .report_type !== "custom_report"
        ) {
          setConnectorAccounts((v) => ({
            ...v,
            [connectorKey]: {
              ...v[connectorKey],
              accounts: [
                ...v[connectorKey].accounts,
                {
                  title: `Sheet ${i + 1}`,
                  subtitle: _`Google Sheets`,
                  identifier: dataSourceConnectors[i].id,
                },
              ],
            },
          }));
        }
      } else if (connectorKey === "google-sheets-custom") {
        if (
          (dataSourceConnectors[i] as GoogleSheetsDataSourceData)
            .report_type === "custom_report"
        ) {
          setConnectorAccounts((v) => ({
            ...v,
            [connectorKey]: {
              ...v[connectorKey],
              accounts: [
                ...v[connectorKey].accounts,
                {
                  title: _`Sheet` + ` ${i + 1}`,
                  subtitle: _`Google Sheets Custom`,
                  identifier: dataSourceConnectors[i].id,
                },
              ],
            },
          }));
        }
      } else if (connectorKey === "criteo") {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Account` + ` ${i + 1}`,
                subtitle: "Criteo",
                identifier: dataSourceConnectors[i].id,
              },
            ],
          },
        }));
      } else if (connectorKey.startsWith("klaviyo")) {
        const source = dataSourceConnectors[
          i
        ] as KlaviyoDataSourceConfiguration;
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Account` + ` ${i + 1}`,
                subtitle: source.shopify_url || source.site_id,
                identifier: source.id,
              },
            ],
          },
        }));
      } else if (connectorKey.startsWith("recharge")) {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              {
                title: _`Account` + ` ${i + 1}`,
                subtitle: keyToLabel(connectorKey),
                identifier: dataSourceConnectors[i].id,
              },
            ],
          },
        }));
      } else if (withNewIntegrationService(connectorKey)) {
        setConnectorAccounts((v) => ({
          ...v,
          [connectorKey]: {
            ...v[connectorKey],
            accounts: [
              ...v[connectorKey].accounts,
              getIntegrationDefaultView(connectorKey)(
                dataSourceConnectors[i] as GenericDataSourceConfiguration,
                i,
              ),
            ],
          },
        }));
      } else {
        let connectorDetails;
        try {
          connectorDetails = await getConnectorDetails(
            await auth.getToken(),
            VIEW_KEY_TO_CONNECTOR_MAPPING[connectorKey] ?? connectorKey,
            dataSourceConnectors[i].id,
          );
        } catch {
          NotificationManager.error(
            _`Some connector(s) failed to load the list of accounts`,
          );
        }

        let addedAccountCount = 0;
        if (connectorKey === "google-analytics") {
          (
            (connectorDetails as GADataSourceConfiguration)
              ?.available_profiles || []
          ).forEach((profile) => {
            if (
              (sourceConnector as GADataSourceData).accounts.includes(
                profile.account_id,
              ) &&
              (sourceConnector as GADataSourceData).profiles.includes(
                profile.profile_id,
              )
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                "google-analytics": {
                  ...v["google-analytics"],
                  accounts: [
                    ...v["google-analytics"].accounts,
                    {
                      title: `${profile.account_name} (${profile.account_id})`,
                      subtitle: `${profile.profile_name} (${profile.profile_id})`,
                      identifier: profile.profile_id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "google-analytics-four") {
          (
            (connectorDetails as GAFourDataSourceConfiguration)
              ?.available_properties || []
          ).forEach((profile) => {
            if (
              (sourceConnector as GAFourDataSourceData).accounts.includes(
                profile.account_id,
              ) &&
              (sourceConnector as GAFourDataSourceData).properties.includes(
                profile.property_id,
              )
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                "google-analytics": {
                  ...v["google-analytics"],
                  accounts: [
                    ...v["google-analytics"].accounts,
                    {
                      title: `(GA4) ${profile.account_name} (${profile.account_id})`,
                      subtitle: `${profile.property_name} (${profile.property_id})`,
                      identifier: profile.property_id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "snapchat-ads") {
          (
            (connectorDetails as SnapChatDataSourceConfiguration)
              ?.available_organizations || []
          ).forEach((profile) => {
            if (
              (
                sourceConnector as SnapChatDataSourceData
              ).organizations.includes(profile.id)
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                [connectorKey]: {
                  ...v[connectorKey],
                  accounts: [
                    ...v[connectorKey].accounts,
                    {
                      title: `${profile.name}`,
                      subtitle: _`Account` + ` ${profile.id}`,
                      identifier: profile.id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "facebook-ads") {
          (
            (connectorDetails as FbDataSourceConfiguration)
              ?.available_accounts || []
          ).forEach((profile) => {
            if (
              (sourceConnector as FbDataSourceData).accounts.includes(
                profile.id,
              )
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                [connectorKey]: {
                  ...v[connectorKey],
                  accounts: [
                    ...v[connectorKey].accounts,
                    {
                      title: `${profile.name}`,
                      subtitle: _`Account` + ` ${profile.id}`,
                      identifier: profile.id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "amazon-ads") {
          (
            (connectorDetails as AmazonAccount)?.available_profiles || []
          ).forEach((profile) => {
            if (
              (
                sourceConnector as AmazonDataSourceConfiguration
              ).profiles.includes(profile.profile_id)
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                [connectorKey]: {
                  ...v[connectorKey],
                  accounts: [
                    ...v[connectorKey].accounts,
                    {
                      title: `${profile.account_name}`,
                      subtitle: _`Account` + ` ${profile.profile_id}`,
                      identifier: profile.profile_id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "tiktok-ads") {
          (
            (connectorDetails as TikTokDataSourceConfiguration)
              ?.available_accounts || []
          ).forEach((profile) => {
            if (
              (sourceConnector as TikTokDataSourceData).accounts.includes(
                profile.id,
              )
            ) {
              addedAccountCount++;
              setConnectorAccounts((v) => ({
                ...v,
                [connectorKey]: {
                  ...v[connectorKey],
                  accounts: [
                    ...v[connectorKey].accounts,
                    {
                      title: `${profile.name}`,
                      subtitle: _`Account` + ` ${profile.id}`,
                      identifier: profile.id,
                    },
                  ],
                },
              }));
            }
          });
        } else if (connectorKey === "bing-ads") {
          (
            (connectorDetails as BingDataSourceConfiguration)
              ?.available_customers || []
          ).forEach((profile) => {
            profile.accounts.forEach((account) => {
              if (
                (sourceConnector as BingDataSourceData).accounts.includes(
                  account.account_id,
                )
              ) {
                addedAccountCount++;
                setConnectorAccounts((v) => ({
                  ...v,
                  [connectorKey]: {
                    ...v[connectorKey],
                    accounts: [
                      ...v[connectorKey].accounts,
                      {
                        title: `${account.name}`,
                        subtitle: _`Account` + ` ${account.account_id}`,
                        identifier: account.account_id,
                      },
                    ],
                  },
                }));
              }
            });
          });
        } else if (connectorKey === "google-ads") {
          (
            (connectorDetails as GAdsDataSourceConfiguration)
              ?.available_customers || []
          ).forEach((customer) => {
            customer.accounts.forEach((account) => {
              if (
                (sourceConnector as GAdsDataSourceData).customer_id ===
                  customer.account_id &&
                (sourceConnector as GAdsDataSourceData).accounts.includes(
                  account.account_id,
                )
              ) {
                addedAccountCount++;
                setConnectorAccounts((v) => ({
                  ...v,
                  [connectorKey]: {
                    ...v[connectorKey],
                    accounts: [
                      ...v[connectorKey].accounts,
                      {
                        title: `${account.name} (${account.account_id})`,
                        subtitle: `${customer.name} (${customer.account_id})`,
                        identifier: account.account_id,
                      },
                    ],
                  },
                }));
              }
            });
          });
        }

        if (addedAccountCount === 0 && connectorKey !== "google-analytics") {
          setConnectorAccounts((v) => ({
            ...v,
            [connectorKey]: {
              ...v[connectorKey],
              datasourceError: true,
            },
          }));
        }
      }
    }

    viewsBeingEdited[index].connectors[connectorId].stores =
      [...viewsBeingEdited[index].connectors[connectorId].stores] || [];
    connectorAccounts[connectorKey].accountsLoaded = true;

    const configuredStoreIdentifiers: string[] = viewsBeingEdited[
      index
    ].connectors[connectorId].stores.map((a) => a.identifier);

    connectorAccounts[connectorKey]?.accounts.forEach((store) => {
      if (!configuredStoreIdentifiers.includes(store.identifier)) {
        viewsBeingEdited[index].connectors[connectorId].stores.push({
          connectorKey,
          title: store.title,
          subtitle: store.subtitle,
          identifier: store.identifier,
          checked: false,
          opened: true,
          rules: [
            ...connectorAccounts[connectorKey].defaultRules.map((d) => ({
              ...d,
            })),
          ],
        });
      }
    });

    const listedStoreIdentifiers: string[] = (
      connectorAccounts[connectorKey]?.accounts || []
    ).map((s) => s.identifier);
    if (connectorKey === "google-analytics") {
      listedStoreIdentifiers.push(
        ...(connectorAccounts["google-analytics-four"]?.accounts || []).map(
          (s) => s.identifier,
        ),
      );
    }
    if (connectorKey === "google-analytics-four") {
      listedStoreIdentifiers.push(
        ...(connectorAccounts["google-analytics"]?.accounts || []).map(
          (s) => s.identifier,
        ),
      );
    }

    dataSourceConnectorsByKey[connectorKey] = dataSourceConnectors;

    viewsBeingEdited[index].connectors[connectorId].stores = viewsBeingEdited[
      index
    ].connectors[connectorId].stores
      .filter((s) => listedStoreIdentifiers.includes(s.identifier))
      .map(patchStoreTitle(connectorKey));

    setViews([...viewsBeingEdited]);

    setConnectorStoresStatus((s) => ({ ...s, [connectorKey]: true }));
  };

  const toggleConnectorStoreOpen = (
    viewId: string,
    connectorId: number,
    storeId: number,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].opened =
      !viewsBeingEdited[index].connectors[connectorId].stores[storeId].opened;
    setViews([...viewsBeingEdited]);
  };

  const toggleConnectorStoreCheck = (
    viewId: string,
    connectorId: number,
    storeId: number,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].checked =
      !viewsBeingEdited[index].connectors[connectorId].stores[storeId].checked;
    setViews([...viewsBeingEdited]);
  };

  const addViewConnectorStoreRule = (
    viewId: string,
    connectorId: number,
    storeId: number,
    rule: CustomViewRule,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].rules.push(
      rule,
    );
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].opened =
      true;
    setViews([...viewsBeingEdited]);
  };

  const deleteViewConnectorStoreRule = (
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[
      storeId
    ].rules.splice(ruleId, 1);
    setViews([...viewsBeingEdited]);
  };

  const editViewConnectorStoreRule = (
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
    rule: CustomViewRule,
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].rules[
      ruleId
    ] = rule;
    setViews([...viewsBeingEdited]);
  };

  const editViewConnectorStoreRuleKey = <T extends keyof CustomViewRule>(
    viewId: string,
    connectorId: number,
    storeId: number,
    ruleId: number,
    key: T,
    value: CustomViewRule[T],
  ) => {
    const index = viewsBeingEdited.findIndex((v) => v.id === viewId);
    viewsBeingEdited[index].connectors[connectorId].stores[storeId].rules[
      ruleId
    ][key] = value;
    setViews([...viewsBeingEdited]);
  };

  const selectedViews = useMemo(
    () =>
      customViewGroups
        ? (selectedViewIds
            .map((selectedViewId) => {
              const [viewGroupId, viewId] = selectedViewId.split("-");
              return customViewGroups
                ?.find((viewGroup) => `${viewGroup.id}` === viewGroupId)
                ?.json_value?.find((view) => view.id === viewId);
            })
            .filter((view) => typeof view !== "undefined") as CustomView[])
        : [],
    [customViewGroups, selectedViewIds],
  );

  const doViewsCoverConnectors = (
    selectedViews: CustomView[],
    tables: string[] | string[][],
    tableKeyToConnectorKey: { [key: string]: string[] },
  ) => {
    const isAnyViewSelected = !!selectedViews[0];

    if (tables.length === 0) {
      return true;
    }

    const doesViewsCoverListOfTables = (tables: string[]) => {
      return selectedViews.some(
        (view) =>
          !view.connectors.length ||
          view.connectors.some((connector) =>
            tables.some((tableKey) => {
              const key = tableKey.includes(".")
                ? tableKey.split(".")[0]
                : tableKey;

              return tableKeyToConnectorKey[key]?.includes(
                connector.connectorKey,
              );
            }),
          ),
      );
    };

    const requirementList = Array.isArray(tables[0])
      ? (tables as string[][])
      : [tables as string[]];
    return (
      !isAnyViewSelected ||
      requirementList.find((list) => !doesViewsCoverListOfTables(list)) ===
        undefined
    );
  };

  const doSelectedViewsCoverConnector = useCallback(
    (
      connectors: string[] | string[][],
      tableKeyToConnectorKey: { [key: string]: string[] },
    ) => {
      return doViewsCoverConnectors(
        selectedViews,
        connectors,
        tableKeyToConnectorKey,
      );
    },
    [selectedViews],
  );

  const doViewsCoverAccountId = (
    selectedViews: CustomView[],
    accountId: string,
  ) => {
    const isCoveredBySelectedViews = selectedViews.some(
      (view) =>
        !view.connectors.length ||
        view.connectors.some((connector) =>
          connector.stores.some((store) => store.identifier === accountId),
        ),
    );

    const isAnyViewSelected = !!selectedViews[0];

    return !isAnyViewSelected || isCoveredBySelectedViews;
  };

  const doSelectedViewsCoverAccountId = useCallback(
    (accountId: string) => {
      return doViewsCoverAccountId(selectedViews, accountId);
    },
    [selectedViews],
  );

  return {
    ruleValueLists,
    loadRuleValueList,
    connectorAccounts,
    connectorStoresStatus,
    viewsBeingEdited,
    setViewsBeingEdited,
    viewBeingEdited,
    viewIndexBeingEdited,
    setViewIndexBeingEdited,
    selectedViews,
    doViewsCoverConnectors,
    doSelectedViewsCoverConnector,
    doSelectedViewsCoverAccountId,
    customViewGroups,
    setCustomViewGroups,
    updateCurrentlyEditedView,
    addConnector,
    duplicateView,
    deleteView,
    toggleConnectorOpen,
    toggleConnectorChildren,
    changeConnectorType,
    deleteViewConnector,
    getViewConnectorStores,
    toggleConnectorStoreOpen,
    toggleConnectorStoreCheck,
    addViewConnectorStoreRule,
    deleteViewConnectorStoreRule,
    editViewConnectorStoreRule,
    editViewConnectorStoreRuleKey,
  };
}
