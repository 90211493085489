import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import {
  useCanSeePixel,
  usePixelConfiguration,
} from "../../hooks/pixelConfiguration";
import useCompose from "../../hooks/useCompose";
import {
  getTenantKlaviyoFlowsSetups,
  KlaviyoFlowsSetup,
} from "../../lib/capiService";
import {
  KlaviyoDataSourceConfiguration,
  getConnectorsOfDataSource,
} from "../../lib/integrationsService";

import { SEGMENTS } from "./components/shared";

interface AudienceData {
  email: string;
  event_type: string;
  event_name: string;
  aov: number;
  conversion_rate: number;
  revenue: number;
  sends: number;
  reach: number;
  store: string;
  is_default: boolean;
}

interface SegmentDetails {
  klaviyo: {
    reach: number;
    revenue: number;
    emails: Set<string>;
    conversionRateSum: number;
    conversionRate: number;
    aovSum: number;
    aov: number;
    stores: Set<string>;
    isDefault: boolean;
  };
  polar: {
    reach: number;
    revenue: number;
    emails: Set<string>;
    conversionRateSum: number;
    conversionRate: number;
    aovSum: number;
    aov: number;
    stores: Set<string>;
    isDefault: boolean;
  };
  klaviyoEmails: string[];
  polarEmails: string[];
  largest: "klaviyoEmails" | "polarEmails";
}

interface AudienceDetails {
  [key: string]: SegmentDetails;
}

interface FlowsSetupsBySegment {
  [key: string]: string[];
}

interface AudienceContextProps {
  loading: boolean;
  tenantHasKlaviyo: boolean;
  tenantHasPixel: boolean;
  tenantHasPixelExtensionData: boolean;
  tenantFirstPixelExtensionEvent: string | null;
  tenantCanInstallPixel?: boolean;
  tenantCanSeeEstimation: boolean | string;
  emailsData: Array<{ event_name: string; email: string }>;
  klaviyoAccountList: string[];
  account: string;
  setAccount: (account: string) => void;
  days: number;
  setDays: (days: number) => void;
  details: AudienceDetails;
  matchedFlows: FlowsSetupsBySegment;
  activationSteps: {
    flowSetup: boolean;
    firstAdditionalCustomer: boolean;
    firstEmailSent: boolean;
    firstIncrementalOrder: boolean;
    accountProfitable: boolean;
  };
}

const AudienceContext = createContext<AudienceContextProps | null>(null);

export function ProvideAudience({ children }: { children: React.ReactNode }) {
  const provider = useProvideAudience();
  return (
    <AudienceContext.Provider value={provider}>
      {children}
    </AudienceContext.Provider>
  );
}

export const useAudience = () => {
  const context = useContext(AudienceContext);
  if (context === null) {
    throw Error("Audience context not provided");
  }
  return context;
};

const useProvideAudience = () => {
  const auth = useAuth();
  const { canSeePixelInstall: tenantCanInstallPixel } = useCanSeePixel();
  const { asAtLeastOnePixelInstalled, isLoadingShops } =
    usePixelConfiguration();
  const { statuses, loaded: connectorStatusesLoaded } =
    useConnectorObservability();
  const { tenant } = useBootstrap();

  const [days, setDays] = useState(30);
  const [account, setAccount] = useState("all");
  const [klaviyoAccountList, setKlaviyoAccountList] = useState<string[]>([]);
  const [flowsSetups, setFlowsSetups] = useState<KlaviyoFlowsSetup[]>([]);

  const {
    loadingData: loadingPixelExtensionData,
    tableData: pixelExtensionData,
  } = useCompose({
    granularity: "none",
    ordering: "prepared.pixelExtension",
    direction: "ASC",
    breakdowns: [],
    metrics: ["prepared.pixelExtension"],
    rules: {},
    views: [],
    options: {
      skipActualTotalData: true,
      skipCompareTotalData: true,
    },
  });

  const tenantHasKlaviyo =
    statuses.filter((s) => s.integration === "klaviyo").length > 0;
  const tenantHasPixel = asAtLeastOnePixelInstalled;
  const tenantHasPixelExtensionData =
    !loadingPixelExtensionData && pixelExtensionData.length > 0;
  const tenantFirstPixelExtensionEvent =
    pixelExtensionData.length > 0 ? pixelExtensionData[0].first_event : null;
  const tenantCanSeeEstimation = tenantHasKlaviyo && tenantHasPixel;

  const { loadingData: loading, tableData: data } = useCompose<AudienceData>({
    granularity: "none",
    ordering: "prepared.audience",
    direction: "ASC",
    breakdowns: [],
    metrics: ["prepared.audience"],
    rules: {},
    views: [],
    extraParams: {
      days: `${days}`,
    },
    options: {
      skipActualTotalData: true,
      skipCompareTotalData: true,
    },
    wait: !tenantCanSeeEstimation,
    cacheKey: "audience-estimate-persisting",
  });

  const details = useMemo((): AudienceDetails => {
    const initializeCategoryData = () => ({
      revenue: 0,
      reach: 0,
      emails: new Set<string>(),
      stores: new Set<string>(),
      isDefault: false,
      aovSum: 0,
      conversionRateSum: 0,
      get conversionRate() {
        return this.emails.size ? this.conversionRateSum / this.emails.size : 0;
      },
      get aov() {
        return this.stores.size ? this.aovSum / this.stores.size : 0;
      },
    });

    const processLine = (acc: AudienceDetails, line: AudienceData) => {
      const {
        event_type,
        event_name,
        revenue,
        email,
        conversion_rate,
        store,
        is_default,
        reach,
        aov,
      } = line;

      if (account !== "all" && store !== account) {
        return acc;
      }

      const category = event_name.includes("Polar") ? "polar" : "klaviyo";
      acc[event_type] = acc[event_type] || {
        polar: initializeCategoryData(),
        klaviyo: initializeCategoryData(),
      };

      const data = acc[event_type][category];
      data.emails.add(email);

      if (!data.stores.has(store)) {
        data.stores.add(store);
        data.revenue += revenue;
        data.reach += reach;
        data.aovSum += aov;
        data.isDefault = is_default;
      }
      data.conversionRateSum += (conversion_rate || 0) * 100;
      return acc;
    };

    const finalizeData = (data: SegmentDetails) => {
      data.polarEmails =
        account === "all"
          ? [...data.polar.emails].filter(
              (email) => !data.klaviyo.emails.has(email),
            )
          : [...data.polar.emails];
      data.klaviyoEmails = [...data.klaviyo.emails];
      data.largest =
        data.klaviyo.reach >= data.polar.reach
          ? "klaviyoEmails"
          : "polarEmails";
    };

    const acc = data.reduce(processLine, {});

    Object.values(acc).forEach((eventType) => finalizeData(eventType));

    for (const type of Object.keys(SEGMENTS)) {
      if (!acc[type]) {
        acc[type] = {
          klaviyoEmails: [],
          polarEmails: [],
          largest: "klaviyoEmails",
          klaviyo: initializeCategoryData(),
          polar: initializeCategoryData(),
        };
      }
    }

    return acc;
  }, [data, account]);

  const matchedFlows = useMemo(() => {
    return flowsSetups.reduce<FlowsSetupsBySegment>((acc, item) => {
      if (account === "all" || item.shopify_url === account) {
        acc[item.trigger_type] = acc[item.trigger_type] ?? [];
        acc[item.trigger_type].push(
          item.original_flow_json.id,
          item.polar_flow_json.id,
        );
      }
      return acc;
    }, {});
  }, [account, flowsSetups]);

  useEffect(() => {
    if (!auth.processing) {
      void (async () => {
        const token = await auth.getToken();
        const data = await getConnectorsOfDataSource<
          KlaviyoDataSourceConfiguration[]
        >(token, "klaviyo");
        setKlaviyoAccountList(data.map((a) => a.shopify_url));
      })();
    }
  }, [auth]);

  useEffect(() => {
    if (
      (tenant.states.displayAudienceResults || tenantCanSeeEstimation) &&
      !auth.processing
    ) {
      void (async () => {
        const token = await auth.getToken();
        const flowsData = await getTenantKlaviyoFlowsSetups(token);
        setFlowsSetups(flowsData);
      })();
    }
  }, [auth, tenant.states.displayAudienceResults, tenantCanSeeEstimation]);

  const activationSteps = useMemo(
    () => ({
      flowSetup: flowsSetups.length > 0,
      firstAdditionalCustomer:
        tenantHasPixelExtensionData &&
        (details.checkout_started.polar.reach > 0 ||
          details.product_added_to_cart.polar.reach > 0 ||
          details.product_viewed.polar.reach > 0),
      firstEmailSent:
        flowsSetups.length > 0 &&
        (details.checkout_started.polar.emails.size > 0 ||
          details.product_added_to_cart.polar.emails.size > 0 ||
          details.product_viewed.polar.emails.size > 0),
      firstIncrementalOrder:
        flowsSetups.length > 0 &&
        (details.checkout_started.polar.revenue > 0 ||
          details.product_added_to_cart.polar.revenue > 0 ||
          details.product_viewed.polar.revenue > 0),
      accountProfitable:
        flowsSetups.length > 0 &&
        details.checkout_started.polar.revenue +
          details.product_added_to_cart.polar.revenue +
          details.product_viewed.polar.revenue >
          500, // TODO: replace with actual value from subscription,
    }),
    [
      flowsSetups.length,
      details.checkout_started.polar.emails.size,
      details.checkout_started.polar.reach,
      details.checkout_started.polar.revenue,
      details.product_added_to_cart.polar.emails.size,
      details.product_added_to_cart.polar.reach,
      details.product_added_to_cart.polar.revenue,
      details.product_viewed.polar.emails.size,
      details.product_viewed.polar.reach,
      details.product_viewed.polar.revenue,
      tenantHasPixelExtensionData,
    ],
  );

  return {
    tenantCanInstallPixel,
    tenantHasKlaviyo,
    tenantHasPixel,
    tenantFirstPixelExtensionEvent,
    tenantHasPixelExtensionData,
    tenantCanSeeEstimation:
      connectorStatusesLoaded && !loadingPixelExtensionData
        ? tenantCanSeeEstimation
        : "loading",
    loading:
      isLoadingShops ||
      ((loading || loadingPixelExtensionData) &&
        tenantCanSeeEstimation === true),
    details,
    emailsData: data,
    days,
    setDays,
    account,
    setAccount,
    klaviyoAccountList,
    matchedFlows,
    activationSteps,
  };
};
