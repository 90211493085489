import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open(
      "https://support.yotpo.com/docs/finding-your-yotpo-app-key-and-secret-key",
      "_blank",
    )
    ?.focus();
};

registerFrontendIntegration({
  key: "yotpo",

  category: INTEGRATION_CATEGORIES.marketing,
  image: "Yotpo",
  label: "Yotpo",
  isNewIntegrationService: true,

  dependsOnAccounts: false,
  backgroundColor: theme.customColors.yotpoBg,
  borderColor: theme.customColors.yotpoBorder,
  get description() {
    return _`E-commerce marketing platform`;
  },
  get userInstructions() {
    return (
      <>
        {_`To learn how to find your App and Secret keys, please read`}{" "}
        <a href="/#" onClick={openTab}>
          {_`this article`}
        </a>
      </>
    );
  },
  provider: "airbyte",
});
