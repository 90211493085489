import { useEffect, useState } from "react";

import {
  SUBSCRIPTION_TYPES,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../common/types/subscription-service";
import Centering from "../../components/Helpers/Centering";
import { Loader } from "../../components/Loader/Loader";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import { useGMVTierBasedPricing } from "../../hooks/useAddonUtils";
import {
  EmptyState,
  Flex,
  Grid,
  Icon,
  ListOptions,
  Select,
  Space,
  Tag,
  Text,
  Tooltip,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import {
  openIntercom,
  setIsIntercomLauncherHidden,
} from "../../utils/intercomUtils";
import { hasActivePaidPlan } from "../../utils/subscriptionsUtils";
import { TRACKING_EVENTS, trackEvent } from "../../utils/trackingUtils";
import { isNonNullable } from "../../utils/typeUtils";
import { EMPTY_ARRAY } from "../../utils/utils";
import MetricDescriptionText from "../Metrics/MetricDescriptionText";

import UpgradeConfirmationPopup, {
  ConfirmationProperties,
} from "./components/ConfirmationPopup";
import { StyledDataSyncingBanner } from "./components/shopifyDataSyncingBanner";
import CustomPlanCard from "./GMVTierBasedPricing/CustomPlanCard";
import GMVActivatePlanCard from "./GMVTierBasedPricing/GMVActivatePlanCard";
import GMVAnalyzePlanCard from "./GMVTierBasedPricing/GMVAnalyzePlanCard";
import GMVEnrichPlanCard from "./GMVTierBasedPricing/GMVEnrichPlanCard";

const PLAN_UPPER_BOUNDS = {
  BASIC: 1_000_000,
  PLUS: 5_000_000,
  ACTIVATE: 20_000_000,
};

interface GMVTierPricingPlanContainerProps {
  style?: React.CSSProperties;
}

const planComponents = {
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLAN]: GMVAnalyzePlanCard,
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN]: GMVEnrichPlanCard,
  [SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN]: GMVActivatePlanCard,
};

const currencyFormatter = new Intl.NumberFormat(undefined, {
  currency: "USD",
  notation: "compact",
  style: "currency",
});

export default function GMVTierPricingPlanContainer(
  _props: GMVTierPricingPlanContainerProps,
) {
  const { isLoading, data: gmvTierBasedPricing } = useGMVTierBasedPricing();
  const { subscription, tenant } = useBootstrap();
  const isOnPaidPlan = hasActivePaidPlan(subscription);
  const isOnCustomPlan =
    tenant.states?.isOnCustomPlan ||
    subscription?.plan === SUBSCRIPTION_TYPES.CUSTOM;
  const [confirmationProperties, setConfirmationProperties] =
    useState<null | ConfirmationProperties>(null);

  const { hasShopifyHistoricalSync } = useConnectorObservability();

  const mergedThresholds = [
    ...(gmvTierBasedPricing?.disabledThresholds.map((t) => ({
      isDisabled: true,
      value: t,
    })) || EMPTY_ARRAY),
    ...(gmvTierBasedPricing?.thresholds.map((t) => ({
      isDisabled: false,
      value: t,
    })) || EMPTY_ARRAY),
  ];

  const thresholdOptions: ListOptions[] =
    mergedThresholds.map(({ value, isDisabled }, i, a) => ({
      value,
      isDisabled,
      label: a[i + 1]
        ? _`${["price1", currencyFormatter.format(value)]} to ${[
            "price2",
            currencyFormatter.format(a[i + 1].value),
          ]}`
        : `${currencyFormatter.format(value)}+`,
      ...(value === gmvTierBasedPricing?.currentTier?.threshold
        ? {
            rightComponents: [
              <Tag withBorder color="primary">{_`Current`}</Tag>,
            ],
          }
        : value === gmvTierBasedPricing?.recommendedThreshold
        ? {
            rightComponents: [
              <Tag withBorder color="primary">{_`Recommended`}</Tag>,
            ],
          }
        : null),
    })) || EMPTY_ARRAY;

  const [threshold, setThreshold] = useState<number | null>(null);

  const onHelp = () => {
    openIntercom();
  };

  useEffect(() => {
    setIsIntercomLauncherHidden(true);
    return () => {
      setIsIntercomLauncherHidden(false);
    };
  }, []);

  if (isLoading)
    return (
      <>
        <Text variant="body16Medium">{_`Pricing Plans`}</Text>
        <Centering style={{ minHeight: 60 }}>
          <Loader />
        </Centering>
      </>
    );

  if (!gmvTierBasedPricing) {
    return (
      <>
        <Text variant="body16Medium">{_`Pricing Plans`}</Text>
        <EmptyState
          heading={_`Unable to load pricing plans`}
          subheading={_`Please refresh the page or contact sales if the issue persists`}
          buttonText={_`Contact sales`}
          onButtonClick={onHelp}
        />
      </>
    );
  }

  const applicableThreshold =
    threshold === null
      ? gmvTierBasedPricing.thresholds.find(
          (t) => t === gmvTierBasedPricing?.currentTier?.threshold,
        ) ??
        gmvTierBasedPricing?.recommendedThreshold ??
        null
      : threshold;

  const tiersToDisplay =
    applicableThreshold === null
      ? EMPTY_ARRAY
      : [
          ...Object.values(gmvTierBasedPricing.plans)
            .map((plan) => {
              const tier = [...plan.tiers]
                .sort((tierA, tierB) => tierB.threshold - tierA.threshold)
                .find(({ threshold }) => threshold <= applicableThreshold);

              const isOnAnalyzeAndEnrich =
                subscription?.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN;
              const isOnActivate =
                subscription?.plan ===
                SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_ACTIVATE_PLAN;
              const isUserOnHigherPlanThanThis =
                (isOnAnalyzeAndEnrich &&
                  plan.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLAN) ||
                (isOnActivate &&
                  (plan.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLAN ||
                    plan.plan === SUBSCRIPTION_TYPES.USAGE_BASED_PLUS_PLAN));
              if (
                !tier ||
                tier.mode === "unavailable" ||
                !planComponents[plan.plan] ||
                isOnCustomPlan ||
                isUserOnHigherPlanThanThis
              ) {
                return null;
              }
              return {
                ...tier,
                plan: plan.plan,
                wrapperId: plan.id,
              };
            })
            .filter(isNonNullable),
        ];

  const highlightedPlan = !gmvTierBasedPricing.recommendedThreshold
    ? null
    : gmvTierBasedPricing.recommendedThreshold < PLAN_UPPER_BOUNDS.BASIC
    ? SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN
    : gmvTierBasedPricing.recommendedThreshold < PLAN_UPPER_BOUNDS.PLUS
    ? SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN
    : gmvTierBasedPricing.recommendedThreshold < PLAN_UPPER_BOUNDS.ACTIVATE
    ? SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN
    : SUBSCRIPTION_TYPE_NAMES.CUSTOM;

  return (
    <>
      <Text variant="body16Medium">{_`Pricing Plans`}</Text>

      {confirmationProperties ? (
        <UpgradeConfirmationPopup
          trackingProperties={{
            currentTier: gmvTierBasedPricing.currentTier,
            recommendedThreshold: gmvTierBasedPricing.recommendedThreshold,
          }}
          properties={confirmationProperties}
        />
      ) : null}

      {!isOnCustomPlan && (
        <>
          <Space size={2} />

          <Flex gap={4}>
            <Text
              variant="body12Regular"
              color={theme.colors.text80}
            >{_`Based on the last 12 months GMV. How do I calculate my GMV?`}</Text>

            <Tooltip
              content={
                <div style={{ width: 250 }}>
                  <Text variant="body12Regular" color={theme.colors.text90}>
                    {_`We calculate GMV using this data:`}
                  </Text>
                  <Space size={8} />
                  <MetricDescriptionText
                    description={[
                      {
                        label: `Gross Sales (${_`Last 12 Months`})`,
                        image: "Shopify",
                      },
                    ]}
                  />
                </div>
              }
              white
              style={{ display: "inline-flex", alignItems: "center" }}
              wrapperStyle={{ width: 100 }}
            >
              <Icon name="InfoCircle" size={14} color={theme.colors.text80} />
            </Tooltip>
          </Flex>

          <Space />

          <Select
            label={_`Select your last 12 months GMV`}
            style={{ maxWidth: 320 }}
            options={thresholdOptions}
            selected={
              applicableThreshold === null ? EMPTY_ARRAY : [applicableThreshold]
            }
            onChange={(values) => {
              setThreshold(values[0] as number);
              trackEvent(TRACKING_EVENTS.GMV_THRESHOLD_SELECTED, {
                disabledThresholds: gmvTierBasedPricing.disabledThresholds,
                thresholds: gmvTierBasedPricing.thresholds,
                currentTier: gmvTierBasedPricing.currentTier,
                recommendedThreshold: gmvTierBasedPricing.recommendedThreshold,
                newThreshold: values[0],
              });
            }}
          ></Select>

          <Space />
        </>
      )}

      {hasShopifyHistoricalSync && !isOnCustomPlan && (
        <StyledDataSyncingBanner />
      )}

      <Grid
        style={{ margin: "auto", maxWidth: tiersToDisplay.length * 600 }}
        gridTemplateColumns={`repeat(${tiersToDisplay.length}, 1fr)`}
        justifyContent="center"
      >
        {tiersToDisplay.map((tier, id) => {
          const PlanComponent = planComponents[tier.plan];
          return (
            <PlanComponent
              key={`${tier.plan}-${tier.threshold}`}
              price={tier.price}
              tier={tier}
              isShowingPreviousPlan={id > 0}
              setConfirmationProperties={setConfirmationProperties}
              currentTier={gmvTierBasedPricing.currentTier}
              recommendedThreshold={gmvTierBasedPricing.recommendedThreshold}
              highlightedPlan={highlightedPlan}
              blockSelfUpgrade={
                isOnPaidPlan && !gmvTierBasedPricing.isSetUpOnStripe
              }
              gmvTierBasedPricing={gmvTierBasedPricing}
            />
          );
        })}
      </Grid>
      {(applicableThreshold || 0) > PLAN_UPPER_BOUNDS.ACTIVATE && (
        <Flex justifyContent="center">
          <CustomPlanCard highlightedPlan={highlightedPlan} isOnlyCard />
        </Flex>
      )}
    </>
  );
}
