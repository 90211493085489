import { ObjectValues } from "../common/types";
export const TRACKING_EVENTS = {
  WINDOW_FOCUS: "Window Focus",
  WINDOW_BLUR: "Window Blur",
  MOBILE_VERSION: "Mobile Version",

  ADD_ACCOUNT: "Add account",

  LIVE_DEMO_LOGIN: "Live demo",
  DEMO_TOUR_STARTED: "Demo Tour Started",

  DEMO_POPUP_ACKNOWLEDGED: "Demo Popup Acknowledged",

  EDIT_FILTER: "Edit Filter",
  EDIT_METRIC_FILTER: "Edit Metric Filter",
  SMART_DATE_FILTER_APPLIED: "Smart Date Filter Applied",

  SETTINGS_UPDATE: "Edit settings",
  INVITE_SENT: "Invite Sent",
  INVITE_START: "Invite Start",
  USER_UPGRADE: "User Upgrade",
  LANGUAGE_CHANGED: "Language Changed",

  VIEW_EDIT: "View Editing",
  VIEW_CREATE: "View Creating",
  VIEW_SAVE: "View Saved",

  USER_SIGNUP_VISITED: "User Signup Visited",
  USER_SIGNUP_SUBMITTED: "User Signup Submitted",
  USER_SIGNUP_FAILED: "User Signup Failed",

  COMPARE_METRIC_STARTED: "compare_metric_started",
  COMPARE_METRIC_SAVED: "compare_metric_saved",

  TOP_FILTER_STARTED: "top_filter_started",
  TOP_FILTER_SAVED: "top_filter_saved",

  COLOR_SCALE_STARTED: "color_scale_started",
  COLOR_SCALE_SAVED: "color_scale_saved",

  TARGETS_STARTED: "targets_started",
  TARGETS_SAVED: "targets_saved",
  TARGETS_DELETED: "targets_delete",
  TARGETS_CLOSED: "targets_closed",

  CHART_GRANULARITY_CHANGED: "chart_granularity_changed",

  KI_SECTION_CHARTS_TOGGLED: "ki_section_charts_toggled",
  KI_SECTION_EDITING: "KI Section Editing",

  KI_SECTION_EDIT_SAVED: "KI Section Edit Saved",
  KI_SECTION_EDIT_TEMPLATE_USED: "KI Section Edit Template Used",
  KI_SECTION_EDIT_CLEAR_METRICS_CLICKED:
    "KI Section Edit Clear Metrics Clicked",

  DETAILED_KI_OPENED: "Detailed KI Opened",
  DETAILED_KI_CLOSED: "Detailed KI Closed",
  DETAILED_KI_EDIT_TARGETS_CLICKED: "Detailed KI Edit Targets Clicked",
  DETAILED_KI_COPY_LINK_CLICKED: "Detailed KI Copy Link Clicked",
  DETAILED_KI_GRANULARITY_CHANGED: "Detailed KI Granularity Changed",

  SCHEDULE_STARTED: "schedule_started",
  SCHEDULE_EDITED: "schedule_edit",
  SCHEDULE_SAVED: "schedule_saved",
  SCHEDULE_DELETED: "schedule_delete",

  CUSTOM_METRIC_CREATE: "Custom Metric Create",
  CUSTOM_METRIC_SAVE: "Custom Metric Save",
  CUSTOM_METRIC_EDIT: "Custom Metric Edit",
  LEFT_MENU_METRIC_CREATE_CLICK: "Left Menu Custom Metric Create",

  CUSTOM_REPORT_CREATE: "Custom Report Creating",
  CUSTOM_REPORT_SAVE: "Custom Report Saved",
  CUSTOM_REPORT_EDITING: "Custom Report Editing",
  CUSTOM_REPORT_DOWNLOADED: "Custom Report Downloaded",
  CUSTOM_REPORT_MODE_CHANGED: "Custom Report Mode Changed",
  CUSTOM_REPORT_CHART_TYPE_CHANGED: "Custom Report Chart Type Changed",

  OPEN_AS_CUSTOM_REPORT_CLICKED: "Open As Custom Report Clicked",

  SECTION_REPORT_SAVED: "Section Report Saved",
  SECTION_REPORT_EDITION_STARTED: "Section Report Edition Started",
  SECTION_REPORT_DOWNLOADED: "Section Report Downloaded",

  CUSTOM_DIMENSION_CREATE: "Custom Dimension Creating",
  CUSTOM_DIMENSION_SAVE: "Custom Dimension Saving",
  CUSTOM_DIMENSION_EDIT: "Custom Dimension Editing",
  LEFT_MENU_DIMENSION_CREATE_CLICK: "Left Menu Custom Dimension Create",

  SETUP_CALL_BOOKED: "Setup Call Booked",

  SETUP_PAGE_VIEWED: "Setup Page Viewed",
  SETUP_PAGE_BOOKING_STEP_READ_MORE_CLICKED:
    "Setup Page Booking Step Read More Clicked",

  OUTBOUND_TEST_B_SEND_REQUEST_CLICKED: "Outbound Test B Send Request Clicked",
  OUTBOUND_TEST_B_GENERATE_REPORT_CLICKED:
    "Outbound Test B Generate Report Clicked",
  OUTBOUND_TEST_B_WHAT_POLAR_OFFERS_CLICKED:
    "Outbound Test B What Polar Offers Clicked",
  OUTBOUND_TEST_B_SAVE_REPORT_BOOK_CALL_OPENED:
    "Outbound Test B Save Report Book Call Opened",
  OUTBOUND_TEST_B_CALL_POPUP_STEP_VIEWED:
    "Outbound Test B Call Popup Step Viewed",
  OUTBOUND_TEST_B_CALL_POPUP_EXPLORE_CARD_CLICKED:
    "Outbound Test B Call Popup Explore Card Clicked",
  OUTBOUND_TEST_B_EXPLORE_ON_MY_OWN_CLICKED:
    "Outbound Test B Explore On My Own Clicked",
  OUTBOUND_TEST_B_CONTINUE_TO_EXPLORE_CLICKED:
    "Outbound Test B Continue to Explore Clicked",
  OUTBOUND_TEST_B_BANNER_SETUP_CALL_CLICKED:
    "Outbound Test B Banner Setup Call Clicked",
  OUTBOUND_TEST_B_REPORT_SAVE_CLICKED: "Outbound Test B Report Save Clicked",
  OUTBOUND_TEST_B_REPORT_ADD_TO_DASHBOARD_CLICKED:
    "Outbound Test B Report Add to Dashboard Clicked",

  AGENCY_OUTBOUND_LANDING_BUTTON_CLICKED:
    "Agency Outbound Landing Button Clicked",

  OUTBOUND_GOAL_LANDING_CARD_CLICKED: "Outbound Goal Landing Card Clicked",

  PHRASE_CHIP_CAROUSEL_CLICK_ATTEMPTED: "Phrase Chip Carousel Click Attempted",

  EXPLORE_SECTION_EXPANDED: "Explore Section Expanded",
  EXPLORE_SECTION_WATCH_VIDEO_CLICKED: "Explore Section Watch Video Clicked",

  EXPLORE_ITEM_APP_LINK_CLICKED: "Explore Item App Link Clicked",
  EXPLORE_ITEM_WATCH_VIDEO_CLICKED: "Explore Item Watch Video Clicked",
  EXPLORE_ITEM_INTERCOM_LINK_CLICKED: "Explore Item Intercom Link Clicked",

  SIGNUP_CTA_CLICKED: "Signup CTA Clicked",
  CONNECT_DATA_BUTTON_CLICKED: "Connect Data Button Clicked",
  CONNECT_ACTIVATE_BUTTON_CLICKED: "Connect Activate Button Clicked",
  CONTINUE_ONBOARDING_BUTTON_CLICKED: "Continue Onboarding Button Clicked",
  BOOK_A_CALL_BUTTON_CLICKED: "Book a Call Button Clicked",

  CASE_STUDY_LINK_CLICKED: "Case Study Link Clicked",
  REVIEW_LINK_CLICKED: "Review Link Clicked",
  INVITE_CARD_COPY_BUTTON_CLICKED: "Invite Card Copy Button Clicked",

  HELP_LINK_CLICKED: "Help Link Clicked",
  LOGOUT_LINK_CLICKED: "Logout Link Clicked",
  SETTINGS_LINK_CLICKED: "Settings Link Clicked",
  REFERRALS_LINK_CLICKED: "Referrals Link Clicked",

  CUSTOMER_JOURNEY_PAGE_CHANGED: "Customer Journey Page Changed",
  CUSTOMER_JOURNEY_ORDERING_CHANGED: "Customer Journey Ordering Changed",
  CUSTOMER_JOURNEY_TOUCH_ALIGNMENT_CHANGED:
    "Customer Journey Touch Alignment Changed",
  CUSTOMER_JOURNEY_CSV_DOWNLOADED: "Customer Journey CSV Downloaded",

  ASK_POLAR_QUESTION_ASKED: "Ask Polar Question Asked",
  ASK_POLAR_OPEN_AS_CUSTOM_REPORT: "Ask Polar Open As Custom Report",
  ASK_POLAR_METRIC_SWITCHED: "Ask Polar Metric Switched",
  ASK_POLAR_COMPARE_METRIC_SAVED: "Ask Polar Compare Metric Saved",
  ASK_POLAR_TOP_FILTER_SAVED: "Ask Polar Top Filter Saved",
  ASK_POLAR_COLOR_SCALE_SAVED: "Ask Polar Color Scale Saved",
  ASK_POLAR_MODE_CHANGED: "Ask Polar Report Mode Changed",
  ASK_POLAR_PROMPT_TYPE_SWITCHED: "Ask Polar Prompt Type Switched",
  ASK_POLAR_INSIGHTS_OPENED: "Ask Polar Insights Opened",

  CUSTOM_DATASETS_OPENED: "Custom Datasets Opened",
  CUSTOM_DATASETS_DATASET_ADDED: "Custom Datasets Dataset Added",
  CUSTOM_DATASETS_DATASET_SAVED: "Custom Datasets Dataset Saved",
  CUSTOM_DATASETS_DATASET_CANCELLED: "Custom Datasets Dataset Cancelled",
  CUSTOM_DATASETS_DATASET_DELETED: "Custom Datasets Dataset Deleted",

  CREATIVE_STUDIO_EDIT_SELECTION_CLICKED:
    "Creative Studio Edit Selection Clicked",
  CREATIVE_STUDIO_TOP_PERFORMERS_SAVED: "Creative Studio Top Performers Saved",
  CREATIVE_STUDIO_SELECT_CREATIVES_SAVED:
    "Creative Studio Select Creatives Saved",
  CREATIVE_STUDIO_CREATIVE_TYPE_SELECTED:
    "Creative Studio Creative Type Selected",
  CREATIVE_STUDIO_METRICS_SELECTED: "Creative Studio Metrics Selected",
  CREATIVE_STUDIO_ORDER_BY_CHANGED: "Creative Studio Order By Changed",
  CREATIVE_STUDIO_SELECT_CREATIVES_FILTERS_CHANGED:
    "Creative Studio Select Creatives Filters Changed",
  CREATIVE_STUDIO_DISPLAY_TYPE_CHANGED: "Creative Studio Display Type Changed",
  CREATIVE_STUDIO_MORE_INFO_FOR_AD_VIEWED:
    "Creative Studio More Info For Ad Viewed",
  CREATIVE_STUDIO_CHANNEL_SELECTOR_CHANGED:
    "Creative Studio Channel Selector Changed",
  CREATIVE_STUDIO_MARKETING_PAGE_BOOK_CALL_CLICKED:
    "Creative Studio Marketing Page Book Call Clicked",
  CREATIVE_STUDIO_MARKETING_PAGE_CONNECT_FACEBOOK_CLICKED:
    "Creative Studio Marketing Page Connect Facebook Clicked",
  CREATIVE_STUDIO_MARKETING_PAGE_CONTINUE_ONBOARDING_CLICKED:
    "Creative Studio Marketing Page Continue Onboarding Clicked",

  FRONTEND_AB_TEST_VERSION_SET: "Frontend AB Test Version Set",

  REQUEST_CONNECTOR_OPENED: "Request Connector Opened",
  REQUEST_CONNECTOR_REQUEST_SENT: "Request Connector Request Sent",

  CONNECTOR_STATUS_DETAIL_TOGGLED: "Connector Status Detail Toggled",

  SYNC_STATUS_HOVERED: "Sync Status Hovered",
  SYNC_TOOLTIP_CONNECTOR_CLICKED: "Sync Status Connector Clicked",

  SHOW_MORE_CONNECTORS_TOGGLED: "Show More Connectors Toggled",
  SHOW_ONLY_CONFIGURED_CONNECTORS_TOGGLED:
    "Show Only Configured Connectors Toggled",

  PERFORMANCE_EVENT_RECEIVED: "Performance Event Received",

  PIXEL_SETUP_COPIED_UTM_CODE: "Pixel Setup Copied UTM Code",
  PIXEL_SETUP_AUTOMATICALLY_SET_UTM_CODE:
    "Pixel Setup Automatically Set UTM Code",
  PIXEL_SETUP_CTA_CLICKED: "Pixel Setup CTA Clicked",

  CAPI_REQUEST_FREE_TRIAL: "CAPI Request Free Trial",

  ONBOARDING_FORM_CLICK_PREVIOUS: "Onboarding Form Click Previous",
  ONBOARDING_FORM_NEXT_STEP: "Onboarding Form Click Next",
  ONBOARDING_FORM_COMPLETED: "Onboarding Form Completed",

  DRILLDOWN_ROW_CLICKED: "Drilldown Row Clicked",
  DRILLDOWN_CELL_CLICKED: "Drilldown Cell Clicked",
  DRILLDOWN_BUTTON_CLICKED: "Drilldown Button Clicked",

  SUBLINK_CLICKED: "Sublink Click",
  SECTION_CLICKED: "Section Click",
  TAB_CLICKED: "Tab Click",

  REFERRALS_GENERATE_CODE_CLICKED: "Referrals Generate Code Clicked",
  REFERRALS_COPY_TO_CLIPBOARD_CLICKED: "Referrals Copy to Clipboard Clicked",
  REFERRALS_TEMS_AND_CONDITIONS_CLICKED:
    "Referrals Terms and Conditions Clicked",
  ATTRIBUTION_MODEL_DROPDOWN_CHANGED: "Attribution Model Dropdown Changed",
  ATTRIBUTION_MODELS_INTERCOM_LINK_CLICKED:
    "Attribution Models Intercom Link Clicked",
  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_CHECKBOX_CLICKED:
    "Attribution UX Channel Performance Checkbox Clicked",
  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_METRIC_RESELECTED:
    "Attribution UX Channel Performance Metric Reselected",

  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_QUICK_FILTER_CLICKED:
    "Attribution UX Channel Performance Quick Filter Clicked",

  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_ORDER_DETAILS_CLICKED:
    "Attribution UX Channel Performance Order Details Clicked",
  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_ORDER_JOURNEY_CLICKED:
    "Attribution UX Channel Performance Order Journey Clicked",

  ATTRIBUTION_UX_METRIC_AND_COLORSCALE_POPUP_BUTTON_CLICKED:
    "Attribution UX Metric and Colorscale Popup Button Clicked",
  ATTRIBUTION_UX_METRIC_SEARCHED_IN_POPUP:
    "Attribution UX Metric Searched in Popup",
  ATTRIBUTION_UX_METRIC_SELECTED_IN_POPUP:
    "Attribution UX Metric Selected in Popup",
  ATTRIBUTION_UX_METRIC_REMOVED_IN_POPUP:
    "Attribution UX Metric Removed in Popup",
  ATTRIBUTION_UX_METRIC_INCOMPATIBLE_RESET_IN_POPUP:
    "Attribution UX Metric Incompatible Reset in Popup",
  ATTRIBUTION_UX_METRIC_LIST_SAVED_IN_POPUP:
    "Attribution UX Metric List Saved in Popup",
  ATTRIBUTION_UX_CHANNEL_PERFORMANCE_BREAKDOWN_CHANGED:
    "Attribution UX Channel Performance Breakdown Changed",

  ATTRIBUTION_UX_DUPLICATE_CHANNEL_GROUPING:
    "Attribution UX Duplicate Channel Grouping",
  ATTRIBUTION_UX_CREATE_CHANNEL_GROUPING:
    "Attribution UX Create Channel Grouping",
  ATTRIBUTION_UX_EDIT_CHANNEL_GROUPING: "Attribution UX Edit Channel Grouping",
  ATTRIBUTION_UX_DELETE_CHANNEL_GROUPING:
    "Attribution UX Delete Channel Grouping",
  ATTRIBUTION_UX_EXPAND_COLLAPSED_REPORT:
    "Attribution UX Expand Collapsed Report",
  ATTRIBUTION_UX_MARKETING_INDICATOR_CHART_DISPLAY_TOGGLED:
    "Attribution UX Marketing Indicator Chart Display Toggled",
  ATTRIBUTION_UX_CAMPAIGN_EDITION_POPUP_OPENED:
    "Attribution UX Campaign Edition Popup Opened",
  ATTRIBUTION_UX_CAMPAIGN_EDITION_POPUP_SAVED:
    "Attribution UX Campaign Edition Popup Saved",
  ATTRIBUTION_UX_CAMPAIGN_EDITION_REQUEST_CLICKED:
    "Attribution UX Campaign Edition Request Clicked",
  ATTRIBUTION_UX_CAMPAIGN_EDITION_POPUP_RECONNECT_FACEBOOK_CLICKED:
    "Attribution UX Campaign Edition Popup Reconnect Facebook Clicked",
  ATTRIBUTION_UX_CAMPAIGN_INSIGHT_SWITCH_CLICKED:
    "Attribution UX Campaign Insight Switch Clicked",
  ATTRIBUTION_UX_CAMPAIGN_INSIGHT_ACTION:
    "Attribution UX Campaign Insight Action",
  ATTRIBUTION_UX_REPORT_ORDERING_CHANGED:
    "Attribution UX Report Ordering Changed",
  KEY_INDICATOR_EXPLORE_WHY_CLICKED: "Key Indicator Explore Why Clicked",

  SUGGEST_A_FEATURE_OPENED: "Suggest a Feature Opened",

  METRIC_RECIPE_TREE_CARD_CLICKED: "Metric Recipe Tree Card Clicked",
  METRIC_RECIPE_BENCHMARK_INDUSTRY_CHANGED:
    "Metric Recipe Benchmark Industry Changed",
  METRIC_RECIPE_OPEN_AS_CUSTOM_REPORT: "Metric Recipe Open As Custom Report",
  METRIC_RECIPE_EDIT_TARGET: "Metric Recipe Edit Target",
  METRIC_RECIPE_TABLE_BREAKDOWNS_CHANGED:
    "Metric Recipe Table Breakdowns Changed",

  GMV_THRESHOLD_SELECTED: "GMV Threshold Selected",
  GMV_PLAN_CHANGE_CLICKED: "GMV Plan Change Clicked",
  GMV_PLAN_CHANGE_CONFIRM_CLICKED: "GMV Plan Change Confirm Clicked",
  GMV_PLAN_CHANGE_DECLINE_CLICKED: "GMV Plan Change Decline Clicked",
  GMV_CURRENT_PLAN_CLICKED: "GMV Current Plan Clicked",
  PLAN_SUBSCRIBE_VIA_POPUP: "Plan Subscription Via Popup",

  FIXED_PRICING_THRESHOLD_SELECTED: "Fixed Pricing Threshold Selected",
  FIXED_PRICING_PLAN_CHANGE_CLICKED: "Fixed Pricing Plan Change Clicked",
  FIXED_PRICING_PLAN_CHANGE_CONFIRM_CLICKED:
    "Fixed Pricing Plan Change Confirm Clicked",
  FIXED_PRICING_PLAN_CHANGE_DECLINE_CLICKED:
    "Fixed Pricing Plan Change Decline Clicked",
  FIXED_PRICING_CURRENT_PLAN_CLICKED: "Fixed Pricing Current Plan Clicked",

  PRICING_BOOK_CALL_CLICKED: "Pricing Book Call Clicked",

  DATA_SETTINGS_LINK_CLICKED: "Data Settings Link Clicked",
  SHOW_UNDERLYING_DATA_CLICKED: "Show Underlying Data Clicked",
  SHOW_UNDERLYING_DATA_BY_ORDER_CLICKED:
    "Show Underlying Data By Order Clicked",

  EXPLORE_WHY_CUSTOM_REPORT_CLICKED: "Explore Why Custom Report Clicked",
  EXPLORE_WHY_CHANNEL_PERFORMANCE_TABLE_CLICKED:
    "Explore Why Channel Performance Table Clicked",
  EXPLORE_WHY_CREATE_REPORT_CLICKED: "Explore Why Create Report Clicked",
  EXPLORE_WHY_METRIC_TOOLTIP_CLICKED: "Explore Why Metric Tooltip Clicked",

  METRIC_RECIPE_ANNOTATION_HOVERED: "Metric Recipe Annotation Hovered",
  METRIC_RECIPE_ANNOTATION_CREATED: "Metric Recipe Annotation Created",
  METRIC_RECIPE_ANNOTATION_DELETED: "Metric Recipe Annotation Deleted",
  METRIC_RECIPE_ANNOTATION_EDITED: "Metric Recipe Annotation Edited",
  METRIC_RECIPE_ANNOTATION_POPUP_OPENED:
    "Metric Recipe Annotation Popup Opened",

  ACTIVATE_WATCH_VIDEO: "Activate Watch Video",
  ACTIVATE_CONNECT_KLAVIYO: "Activate Connect Klaviyo",
  ACTIVATE_SETUP_PIXEL: "Activate Setup Pixel",
  ACTIVATE_BOOK_A_CALL: "Activate Book A Call",
  ACTIVATE_DOWNLOAD_CSV: "Activate Download CSV",
  ACTIVATE_UPGRADE: "Activate Upgrade",
  ACTIVATE_INVITE_POLAR_TO_KLAVIYO: "Invite Polar to Klaviyo",

  GSHEET_FORM_RELOAD_AND_PREVIEW_CLICKED:
    "GSheet Form Reload and Preview Clicked",
  GSHEET_FORM_CONNECT_CLICKED: "GSheet Form Connect Clicked",
  GSHEET_RELOAD_PREVIEW_CLICKED: "GSheet Reload Preview Clicked",
  GSHEET_POPUP_URL_LINK_CLICKED: "GSheet Popup Url Link Clicked",
  GSHEET_SYNC_DATA_CLICKED: "GSheet Sync Data Clicked",
  GSHEET_FILTER_TAB_ROWS_CLICKED: "GSheet Filter Tab Rows Clicked",
  GSHEET_TEMPLATE_CLICKED: "GSheet Template Clicked",
  GSHEET_COLUMN_CONFIG_CHANGED: "GSheet Column Config Changed",
  GSHEET_FORM_SEE_ERRORS_CLICKED: "GSheet Form See Errors Clicked",

  OMNISEARCH_METRIC_SELECTED: "Omnisearch Metric Selected",
  OMNISEARCH_REPORT_SELECTED: "Omnisearch Report Selected",
  OMNISEARCH_DASHBOARD_SELECTED: "Omnisearch Dashboard Selected",
  OMNISEARCH_CREATE_METRIC_REPORT_SELECTED:
    "Omnisearch Create Metric Report Selected",

  CLICK_LOCK_DATE_RANGE_BUTTON: "Lock Date Range",
  CLICK_LOCK_GRANULARITY_BUTTON: "Lock Granularity",
  MODIFY_DATE_RANGE_LOCK: "Modify Date Range Lock",
  MODIFY_GRANULARITY_LOCK: "Modify Granularity Lock",
} as const;

/**
 * Used to choose which integrations to send analytics to. Not a comprehensive
 * list.
 */
export const integrations = {
  segment: "Segment.io",
} as const;
type AnalyticsIntegration = (typeof integrations)[keyof typeof integrations];

type TrackEventOptions = {
  /**
   * Unless specified, sends the event to all the integrations.
   */
  sendTo?: AnalyticsIntegration[];
};

function createIntegrationFilter(sendTo?: AnalyticsIntegration[]) {
  if (!sendTo) {
    return undefined;
  }

  return {
    All: false,
    ...Object.fromEntries(sendTo.map((destination) => [destination, true])),
  };
}

export const trackEvent = (
  eventName: ObjectValues<typeof TRACKING_EVENTS>,
  properties: Record<string, unknown> = {},
  options?: TrackEventOptions,
) => {
  analytics.track(eventName, properties, {
    integrations: createIntegrationFilter(options?.sendTo),
  });
};
