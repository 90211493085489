import moment from "moment/moment";

import { _ } from "../../languages/helper";
import {
  DATE_GRANULARITY_OPTIONS,
  FILTER_TYPES,
  Granularity,
  PREFILTERS,
} from "../../utils/dateUtils";
import { SmartFilterSelector } from "../../utils/filterUtils";

export const getSmartDatePickerDisplayValue = (
  selector: SmartFilterSelector,
  withPopup: boolean,
) => {
  const rangePlaceHolder = `${moment(selector.rangeSelection.start).format(
    "MMM D, YY",
  )} - ${moment(selector.rangeSelection.end).format("MMM D, YY")}`;

  const relativePlaceHolder = `Last ${selector.relativeSelection.count} ${
    selector.relativeSelection.granularity
  }${selector.relativeSelection.count === 1 ? "" : "s"}`;

  const prefilterPlaceHolder =
    PREFILTERS.find((filter) => filter.value === selector.predefinedSelection)
      ?.label || "";

  return withPopup
    ? selector.tab === FILTER_TYPES.PREDEFINED
      ? prefilterPlaceHolder
      : selector.tab === FILTER_TYPES.RELATIVE
      ? relativePlaceHolder
      : rangePlaceHolder
    : rangePlaceHolder;
};

export const getGranularityDisplayValue = (
  granularity: Granularity | "none",
) => {
  return granularity === "none"
    ? _`Entire Range`
    : DATE_GRANULARITY_OPTIONS.find((option) => option.value === granularity)
        ?.label;
};
