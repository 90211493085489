import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "shopify",
  category: INTEGRATION_CATEGORIES.sales_channels,
  image: "Shopify",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.ShopifyBg,
  borderColor: theme.customColors.ShopifyBorder,
  label: "Shopify",

  get description() {
    return _`Multi-channel e-commerce platform`;
  },
  views: [
    {
      key: "shopify",
      extraFilters: [
        {
          value: "ga_source",
          get label() {
            return _`GA Sources`;
          },
        },
        {
          value: "ga_campaign",
          get label() {
            return _`GA Campaigns`;
          },
        },
        {
          value: "ga_medium",
          get label() {
            return _`GA Mediums`;
          },
        },
      ],
    },
  ],
  canReconnectOAuth: true,
  provider: "fivetran",
});
