import { SUBSCRIPTION_TYPE_NAMES } from "../../../common/types/subscription-service";
import { PricingModels } from "../../../common/types/users-service";
import {
  useRedirectToStripeCheckout,
  useTenantPlan,
  useSubscriptionEnd,
} from "../../../hooks/useAddonUtils";
import {
  Box,
  SubscriptionPlanCard,
  SubscriptionPlanFooter,
  SubscriptionPlanHeader,
  SubscriptionPlanListDivider,
} from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { trackEvent, TRACKING_EVENTS } from "../../../utils/trackingUtils";
import {
  ActivateAccountSupport,
  ActivatePlatformAccess,
} from "../components/basicPlanContent";
import { ConfirmationProperties } from "../components/ConfirmationPopup";
import SubscriptionPlanButton from "../components/SubscriptionPlanButton";

type UsageBasedPlusCardProps = {
  price: number;
  isShowingPreviousPlan: boolean;
  setConfirmationProperties: (args: ConfirmationProperties | null) => void;
  blockSelfUpgrade: boolean;
};

const FixedPricingActivateCard = ({
  price,
  isShowingPreviousPlan,
  setConfirmationProperties,
  blockSelfUpgrade,
}: UsageBasedPlusCardProps) => {
  const { isOnUsageBasedPlusActivatePlan } = useTenantPlan();
  const { isSubscriptionEnding, subscriptionEndDate } = useSubscriptionEnd();
  const {
    isRedirecting,
    redirectStripeUsageBasedCheckout,
    redirectToStripeCustomerPortal,
  } = useRedirectToStripeCheckout({ setConfirmationProperties });

  return (
    <SubscriptionPlanCard
      selected={false}
      current={isOnUsageBasedPlusActivatePlan}
    >
      <SubscriptionPlanHeader
        planInfo={null}
        planName={_`Analyze, Enrich & Activate Plan`}
        price={price}
      ></SubscriptionPlanHeader>

      <SubscriptionPlanListDivider />

      <ActivatePlatformAccess showDifference={isShowingPreviousPlan} />

      <SubscriptionPlanListDivider />

      <ActivateAccountSupport showDifference={isShowingPreviousPlan} />

      <Box marginTop="auto" padding="0px 16px 16px 16px">
        <SubscriptionPlanButton
          isOnPlan={isOnUsageBasedPlusActivatePlan}
          redirectStripeUsageBasedCheckout={
            !blockSelfUpgrade
              ? () => {
                  trackEvent(
                    TRACKING_EVENTS.FIXED_PRICING_PLAN_CHANGE_CLICKED,
                    {
                      plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                      price,
                    },
                  );
                  redirectStripeUsageBasedCheckout({
                    pricing_model: PricingModels.FIXED_PRICE,
                    plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                  });
                }
              : undefined
          }
          isRedirecting={isRedirecting}
          subscriptionEndDate={subscriptionEndDate}
          isSubscriptionEnding={isSubscriptionEnding}
          redirectToStripeCustomerPortal={
            // TODO: Check if setup on stripe
            async () => {
              trackEvent(TRACKING_EVENTS.FIXED_PRICING_CURRENT_PLAN_CLICKED, {
                plan: SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_ACTIVATE_PLAN,
                price,
              });

              await redirectToStripeCustomerPortal();
            }
          }
        />

        <SubscriptionPlanFooter>
          {_`Month to month. No commitment`}
        </SubscriptionPlanFooter>
      </Box>
    </SubscriptionPlanCard>
  );
};

export default FixedPricingActivateCard;
