import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";

import { useBootstrap } from "../../hooks/bootstrap";
import { useDatePickerMinMaxDate } from "../../hooks/useDatePickerMinMaxDate";
import { DateRange } from "../../utils/dateUtils";
import {
  getDateRangeFromSelector,
  RuleOperators,
  RuleWithFilter,
  SmartFilterSelector,
} from "../../utils/filterUtils";
import SmartDateFilter from "../SmartDateFilter/SmartDateFilter";

interface DateRuleProps {
  rule: RuleWithFilter;
  onRuleChange: (rule: RuleWithFilter) => void;
  defaultIsOpen: boolean;
}

export const DateRule: FunctionComponent<DateRuleProps> = ({
  rule,
  onRuleChange,
  defaultIsOpen,
}) => {
  const { minDate } = useDatePickerMinMaxDate();
  const [isOpen, setIsOpen] = useState(defaultIsOpen);
  const { getUserTenantSetting, hasPermission } = useBootstrap();
  const maySeeTodayData = hasPermission("data.seeTodayData");

  const selector = rule.value[0] as SmartFilterSelector;
  const setRange = (range: DateRange) =>
    onRuleChange({
      dimensionFilter: rule.dimensionFilter,
      operator: RuleOperators.date,
      value: [{ ...selector, rangeSelection: range }],
    });
  useEffect(() => {
    const updateRange = () => {
      const newRange = getDateRangeFromSelector(
        getUserTenantSetting("weekstart", "sunday") === "sunday" ? 0 : 1,
        selector,
        maySeeTodayData,
        minDate?.toISOString(),
      );
      setRange(newRange);
    };
    updateRange();
    // eslint-disable-next-line
  }, [minDate?.toISOString()]);

  return (
    <SmartDateFilter
      withPopup={false}
      allowCompare={false}
      displayDatePicker={isOpen}
      setDisplayDatePicker={setIsOpen}
      setSelector={(value) => {
        onRuleChange({
          dimensionFilter: rule.dimensionFilter,
          operator: RuleOperators.date,
          value: [value],
        });
      }}
      matchingDayOfWeek={false}
      selector={selector}
      granularity={"none"}
      disableGranularity={true}
      setCompareRange={() => null}
      setComparePeriod={() => null}
      setMatchingDayOfWeek={() => null}
      comparePeriod="range"
      compareRange={{ start: moment(), end: moment() }}
    />
  );
};
