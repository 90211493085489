import React from "react";
import "react-notifications/lib/notifications.css";
import { Outlet, Route } from "react-router-dom";

import { ProvideConnectorData } from "../../hooks/useConnectorData";

const Connectors = React.lazy(
  () => import("../../pages/connectors/Connectors"),
);
const Confirm = React.lazy(() => import("../../pages/shopify/Confirm"));

export const ConnectorRoutes = (
  <>
    <Route
      path="/connectors"
      element={
        <ProvideConnectorData>
          <Outlet />
        </ProvideConnectorData>
      }
    >
      <Route path="" element={<Connectors />} />
      <Route path=":connectorKey" element={<Connectors />} />
      <Route path=":connectorKey/:tab" element={<Connectors />} />
      <Route path=":connectorKey/:tab/:subtab" element={<Connectors />} />
    </Route>
    <Route path="/shopify/confirm" element={<Confirm />} />
  </>
);
