import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "yahoo-dsp",
  image: "YahooDSP",
  label: "Yahoo DSP",
  get description() {
    return _``;
  },
  category: INTEGRATION_CATEGORIES.advertising,
  isCustomConnector: true,
  backgroundColor: theme.customColors.fallbackConnectorBg,
  borderColor: theme.customColors.fallbackConnectorBorder,
  getButtonSection: () => CustomConnectorButtonSection,
  provider: "fivetran",
});
