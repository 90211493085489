import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { CustomConnectorButtonSection } from "../pages/connectors/popups/connect/customConnectors/CustomConnectorButtonSection";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "zoho-campaigns",
  image: "ZohoCampaigns",
  label: "Zoho Campaigns",
  get description() {
    return _``;
  },
  category: INTEGRATION_CATEGORIES.marketing,
  isCustomConnector: true,
  backgroundColor: theme.customColors.zohoBg,
  borderColor: theme.customColors.zohoBorder,
  getButtonSection: () => CustomConnectorButtonSection,
  provider: "fivetran",
});
