import type { PricingModels } from "./users-service";

export type AddonType = "capi" | "hourly_refresh" | "polar_pixel";
export type PlanName =
  | "USAGE_BASED_PLUS_ACTIVATE_PLAN"
  | "USAGE_BASED_PLAN"
  | "USAGE_BASED_PLUS_PLAN"
  | "CUSTOM";
export type PlanPriceMapping = Record<PlanName, number>;
export type UsageBasedPricing = {
  price: PlanPriceMapping;
  orders: number;
  isSetUpOnStripe: boolean;
};

export interface Subscription {
  tenant_id: string;
  plan: number;
  created_at: string;
  valid_until: string | null;
  cancel_at_end: boolean | null;
  add_ons: string[];
}

export const SUBSCRIPTION_TYPES = {
  CUSTOM: -1,
  FREE_TRIAL: 0,
  START: 1,
  GROWTH: 2,
  PLUS: 3,
  FREE_PLAN: 4,
  USAGE_BASED_PLAN: 5,
  USAGE_BASED_PLUS_PLAN: 6,
  USAGE_BASED_PLUS_ACTIVATE_PLAN: 7,
};

export const SUBSCRIPTION_TYPE_NAMES = Object.fromEntries(
  Object.keys(SUBSCRIPTION_TYPES).map((key) => [key, key]),
) as { [Key in keyof typeof SUBSCRIPTION_TYPES]: Key };

export interface GetTenantSubscriptionResult {
  error: boolean;
  result: Subscription | null;
}

export interface PostRegisterShopifyFromSSO {
  error: boolean;
}

export interface GetShopifyToken {
  error: boolean;
  token: string;
  shop: string;
}

export type StripeTierMode =
  | "unavailable"
  | "flat_price_hidden"
  | "flat_price"
  | "custom";

export type StripeTier = {
  tierIndex: number;
  threshold: number;
  price_id?: string;
  mode?: StripeTierMode;
  is_live: boolean;
  price?: number;
  disabled?: boolean;
};

export interface StripeTiers {
  id: string;
  stripe_product_id: string;
  plan: number;
  valid_from?: string;
  valid_until?: string;
  is_draft: boolean;
  created_at: string;
  tiers: StripeTier[];
}

export type StripeTierSearchParams = {
  include_drafts?: boolean;
  include_valid_after?: string;
  include_valid_before?: string;
};

export type TenantTier = {
  tenant_id: string;
  stripe_tiers_id: string;
  active_tier_index: number;
  valid_from?: string;
};

export type GetApplicableGMVTierData = {
  isSetUpOnStripe: boolean;
  disabledThresholds: number[];
  thresholds: number[];
  currentTier: null | {
    threshold: number;
    wrapperId: string;
    tierIndex: number;
    validFrom?: string;
  };
  recommendedThreshold: null | number;

  // Card data
  plans: Record<
    number,
    {
      id: string;
      plan: number;
      tiers: StripeTier[];
    }
  >;
};

export type PlanSelector =
  | {
      pricing_model?: typeof PricingModels.USAGE_BASED;
      plan: PlanName;
    }
  | {
      pricing_model: typeof PricingModels.GMV_TIER_BASED;
      wrapperId?: string;
      tierIndex?: number;
      plan: PlanName;
    }
  | {
      pricing_model?: typeof PricingModels.FIXED_PRICE;
      plan: PlanName;
    };

export type UpgradeStripeSubscriptionBody = {
  returnUrl: string;
} & PlanSelector;

export type CreateStripeCheckoutSessionBody = {
  returnUrl: string;
} & PlanSelector;
