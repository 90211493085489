import moment from "moment";

import { ComposeCompareSettings } from "../hooks/useCompose";
import {
  ComposeRule,
  ComposeRules,
  formatComposeRules,
} from "../utils/composeUtils";
import {
  isRuleGroup,
  RuleOperators,
  SmartFilterSelector,
} from "../utils/filterUtils";
import { CustomReportDefinition } from "../utils/reports";
import { filterIsNotNullOrUndefined } from "../utils/utils";

import { createClient } from "./apiClient";
import { Element } from "./dashboardElement";

const client = createClient("views-service");
export interface Report extends Element {
  position: number;
  section_key?: string;
  json_value: CustomReportDefinition;
}

export const getCustomReports = async (token: string) => {
  const result = await client
    .new()
    .get("/api/reports")
    .auth(token)
    .fetch<{ data: Report[] }>();
  return result.error ? [] : result.data.map(parseDatesInReport);
};

export const createCustomReport = async (
  token: string,
  title: string,
  json: CustomReportDefinition,
  section_key?: string,
) => {
  const result = await client
    .new()
    .post("/api/reports")
    .body({
      title,
      json: JSON.stringify(formatDatesInReport(json)),
      ...(section_key ? { section_key } : {}),
    })
    .auth(token)
    .fetch<{ data: number }>();
  return result.error ? null : result.data;
};

export const patchCustomReport = async (
  token: string,
  title: string,
  json: CustomReportDefinition,
  id: number,
) => {
  const result = await client
    .new()
    .patch("/api/reports/" + id)
    .body({ title, json: JSON.stringify(formatDatesInReport(json)) })
    .auth(token)
    .fetch<{ data: Report }>();
  return result.error ? null : parseDatesInReport(result.data);
};

export const deleteCustomReport = async (
  token: string,
  id: number,
  force?: boolean,
) => {
  const result = await client
    .new()
    .delete("/api/reports/" + id + (force ? "?force=true" : ""))
    .body({})
    .auth(token)
    .fetch();
  return result;
};

const formatDatesInReport = (report: CustomReportDefinition) => {
  const formattedRules = formatComposeRules(report.rules);
  return {
    ...report,
    rules: formattedRules,
  };
};

const parseDateRuleInReport = (dateRule: ComposeRule): ComposeRule => {
  return {
    ...dateRule,
    value: (dateRule.value as SmartFilterSelector[]).map((value) => {
      return {
        ...value,
        rangeSelection: {
          start: moment(value.rangeSelection.start),
          end: moment(value.rangeSelection.end),
        },
      };
    }),
  };
};

const fixRuleOperator = (rule: ComposeRule): ComposeRule => {
  if (rule.operator && !Object.values(RuleOperators).includes(rule.operator)) {
    return {
      ...rule,
      operator: rule.operator.toUpperCase() as RuleOperators, // needed because old reports use lowercase operators
    };
  }
  return rule;
};

const isValidRule = (rule: ComposeRule) => {
  if (rule.operator && !Object.values(RuleOperators).includes(rule.operator)) {
    return false;
  }
  return true;
};

const parseRules = (rules: ComposeRules): ComposeRules => {
  const parseRule = (r: ComposeRule) => {
    if (r.operator === RuleOperators.date) {
      return parseDateRuleInReport(r);
    }
    return r;
  };
  return Object.fromEntries(
    Object.entries(rules).map(([rulesKey, rules]) => {
      const newRules = rules
        .map((rule) => {
          if (isRuleGroup(rule)) {
            return rule.map(fixRuleOperator);
          } else {
            return fixRuleOperator(rule);
          }
        })
        .map((rule) => {
          if (isRuleGroup(rule)) {
            return rule.map((r) => parseRule(r)).filter(isValidRule);
          } else {
            const newRule = parseRule(rule);
            return isValidRule(newRule) ? newRule : null;
          }
        })
        .filter(filterIsNotNullOrUndefined);
      return [rulesKey, newRules];
    }),
  );
};

const parseCompare = (compare: ComposeCompareSettings) => {
  return {
    ...compare,
    ...(compare.compareRange
      ? {
          compareRange: {
            start: moment(compare.compareRange.start),
            end: moment(compare.compareRange.end),
          },
        }
      : {}),
  };
};

const parseDatesInReport = (report: Report): Report => {
  const newReport: Report = {
    ...report,
    json_value: {
      ...report.json_value,
      rules: parseRules(report.json_value.rules),
      ...(report.json_value.compare
        ? { compare: parseCompare(report.json_value.compare) }
        : {}),
      dateRangeLock: report.json_value?.dateRangeLock
        ? {
            ...report.json_value.dateRangeLock,
            rangeSelection: {
              start: moment(
                report.json_value.dateRangeLock.rangeSelection.start,
              ),
              end: moment(report.json_value.dateRangeLock.rangeSelection.end),
            },
          }
        : undefined,
    },
  };
  return newReport;
};
