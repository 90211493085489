import { useCallback, useEffect, useMemo, useState } from "react";

import { getDemoPersonalizationData } from "../lib/usersService";
import {
  DEMO_DOMAIN_NAME_STORAGE_KEY,
  DEMO_EMAIL_STORAGE_KEY,
  DEMO_PERSONALIZED_DATA_STORAGE_KEY,
  OUTBOUND_DEMO_IDENTIFICATION_STORAGE_KEY,
} from "../utils/demoConfig";
import { trackEvent, TRACKING_EVENTS } from "../utils/trackingUtils";

import useLocalStorage from "./useLocalStorage";
import {
  DemoPersonalization,
  OutboundDemoData,
  OutboundDemoIdentification,
} from "./useOutboundDemoTypes";

const DEMO_REGEX = /^\/login\/(.+)/;
const getOutboundDemoDomain = () =>
  DEMO_REGEX.exec(window.location.pathname)?.[1];
export const getQueryObject = (
  outboundDemoIdentification: OutboundDemoIdentification,
) => {
  let identification: {
    domain?: string;
    linkedinName?: string;
    persona?: string;
  } = {
    // support old format url like this /login/test.domain.com => We look them up with the domain name.
    domain: outboundDemoIdentification.domain,
  };
  // to support new linkedin url, the domain would be parsed as linkedin: https://app.polaranalytics.com/login/linkedin?name={{linkedin.name}}
  if (outboundDemoIdentification.domain === "linkedin") {
    identification = {
      linkedinName: outboundDemoIdentification.name,
    };
  }
  // support old format url like this /login?is_demo=true&name={{linkedin.name}} => We look them up with the linkedin name.
  if (!outboundDemoIdentification.domain && outboundDemoIdentification.name) {
    identification = {
      linkedinName: outboundDemoIdentification.name,
    };
  }
  if (outboundDemoIdentification.persona) {
    identification.persona = outboundDemoIdentification.persona;
  }

  return identification;
};

/**
 * Does not provide or use a context, only localStorage. It is not
 * recommended to use multiple instances of this hook in different
 * places, or they might be out of sync.
 */
export const useOutboundDemo = () => {
  const [isFirstPageLoad, setIsFirstPageLoad] = useState(true);

  const [demoDataString, setDemoDataString] = useLocalStorage<string | null>(
    DEMO_PERSONALIZED_DATA_STORAGE_KEY,
    null,
  );
  const [
    outboundDemoIdentificationString,
    setOutboundDemoIdentificationString,
  ] = useLocalStorage<string | null>(
    OUTBOUND_DEMO_IDENTIFICATION_STORAGE_KEY,
    null,
  );

  const { search } = window.location;
  const outboundDemoIdentification: OutboundDemoIdentification = useMemo(() => {
    const newDomain = getOutboundDemoDomain();
    const storedData = JSON.parse(
      outboundDemoIdentificationString || "{}",
    ) as Partial<OutboundDemoIdentification>;

    const result = {
      randomId: crypto.randomUUID(),
      ...(!newDomain || newDomain === storedData.domain ? storedData : {}),
    };

    const pathEmail = new URLSearchParams(search).get("email");
    if (pathEmail) {
      result.email = pathEmail;
    }

    const pathStep = new URLSearchParams(search).get("step");
    if (pathStep) {
      result.step = pathStep;
    }

    const pathVersion = new URLSearchParams(search).get("version");
    if (pathVersion) {
      result.version = pathVersion;
    }

    const pathCampaignId = new URLSearchParams(search).get("utm_campaign");
    if (pathCampaignId) {
      result.campaignId = pathCampaignId;
    }

    const hutspotId = new URLSearchParams(search).get("demo-user-id");
    if (hutspotId) {
      result.hutspotId = hutspotId;
    }

    const name = new URLSearchParams(search).get("name");
    if (name) {
      result.name = name;
    }

    const persona = new URLSearchParams(search).get("persona");
    if (persona) {
      result.persona = persona;
    }

    if (newDomain) {
      result.domain = newDomain;
    }

    return result;
  }, [search, outboundDemoIdentificationString]);

  const outboundDemoData: OutboundDemoData | null = useMemo(() => {
    if (demoDataString === null) {
      return null;
    }

    try {
      const demoPersonalization = JSON.parse(
        demoDataString,
      ) as DemoPersonalization;
      let outboundOnboardingTest: string | undefined = undefined;
      if (
        demoPersonalization.outboundOnboardingTest &&
        outboundDemoIdentification.persona !== "agency"
      ) {
        outboundOnboardingTest = "A";
      }

      return {
        personalization: {
          ...demoPersonalization,
          outboundOnboardingTest,
        },
        ...outboundDemoIdentification,
      };
    } catch (e) {
      console.warn(`Could not parse demo data: ${e}`);
      return null;
    }
  }, [demoDataString, outboundDemoIdentification]);

  const clearOutboundDemoData = useCallback(() => {
    setDemoDataString(null);
    setOutboundDemoIdentificationString(null);

    localStorage.removeItem(DEMO_PERSONALIZED_DATA_STORAGE_KEY);
    localStorage.removeItem(DEMO_DOMAIN_NAME_STORAGE_KEY);
    localStorage.removeItem(DEMO_EMAIL_STORAGE_KEY);
    localStorage.removeItem(OUTBOUND_DEMO_IDENTIFICATION_STORAGE_KEY);
  }, [setDemoDataString, setOutboundDemoIdentificationString]);

  useEffect(() => {
    if (!getOutboundDemoDomain() || !isFirstPageLoad) {
      return;
    }
    setIsFirstPageLoad(false);

    setOutboundDemoIdentificationString(
      JSON.stringify(outboundDemoIdentification),
    );
    setDemoDataString(null);
    void (async () => {
      const queryObject = getQueryObject(outboundDemoIdentification);
      const data = await getDemoPersonalizationData(queryObject);

      if (data !== false) {
        setDemoDataString(JSON.stringify(data));
      }

      setTimeout(() => {
        trackEvent(
          TRACKING_EVENTS.DEMO_TOUR_STARTED,
          outboundDemoIdentification,
        );
      }, 200);
    })();
  }, [
    isFirstPageLoad,
    outboundDemoIdentification,
    demoDataString,
    setDemoDataString,
    setOutboundDemoIdentificationString,
  ]);

  const logIntoOutboundDemo = (email: string) => {
    clearOutboundDemoData();
    setOutboundDemoIdentificationString(JSON.stringify({ email }));
    setDemoDataString(JSON.stringify({}));
  };

  return {
    outboundDemoData,
    isOutboundDemo:
      Boolean(getOutboundDemoDomain()) || Boolean(outboundDemoData),
    clearOutboundDemoData,
    logIntoOutboundDemo,
  };
};
