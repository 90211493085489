import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { resetViewEditor } from "../../components/CountryFilters/views/ViewProvider";
import SmartDateFilterPicker from "../../components/SmartDateFilter/SmartDateFilterPicker";
import useViewSelector from "../../elements/ViewsSelector/ViewsSelector.hook";
import { useAuth } from "../../hooks/auth/auth";
import { useBootstrap } from "../../hooks/bootstrap";
import { useConnectorObservability } from "../../hooks/connectorObservability";
import { useDashboards } from "../../hooks/dashboards";
import { useMetricList } from "../../hooks/metricList";
import { useSmartFilter } from "../../hooks/useSmartFilter";
import {
  Box,
  Chip,
  Dropdown,
  Flex,
  Grid,
  Loader,
  MobileLayout,
  Select,
  Text,
  theme,
} from "../../icecube-ux";
import { _ } from "../../languages/helper";
import { isKISectionElement } from "../../lib/dashboardController";
import DemoDataBanner from "../../shared/Messages/DemoDataBanner";
import { FILTER_TYPES, PREFILTERS } from "../../utils/dateUtils";
import { pluralize } from "../../utils/languageUtils";
import { getMetricIcon } from "../../utils/metricUtils";
import { trackEvent, TRACKING_EVENTS } from "../../utils/trackingUtils";

import MetricCardWrapper from "./components/MetricCardWrapper";
import { getVisibleMetrics } from "./utils";

const MOBILE_MAXIMUM_SECTIONS = 4;

export default function MobileKeyIndicators() {
  const parentRef = useRef<HTMLDivElement>(null);
  const auth = useAuth();
  const { statuses } = useConnectorObservability();
  const navigate = useNavigate();
  const {
    granularity,
    range,
    matchingDayOfWeek,
    comparePeriod,
    compareRange,
    selector,
    setSelector,
    setCompareRange,
    setComparePeriod,
    setMatchingDayOfWeek,
    setSelectedViewIds,
    selectedViewIds,
  } = useSmartFilter();
  const onChange = (v: (string | number)[]) =>
    setSelectedViewIds(v as string[]);
  const {
    getViewsList,
    formatSelected,
    loading: loadingViews,
  } = useViewSelector(onChange, selectedViewIds);
  const { loading, elementNotInDashboard, dashboardsElementsAssociation } =
    useDashboards();

  const [selectedDashboard, setSelectedDashboard] = useState(-2);
  const kiDashboard = dashboardsElementsAssociation.find(
    (d) => d.page === "ki" && d.id === selectedDashboard,
  );

  const sectionsNotInDashboard =
    elementNotInDashboard.filter(isKISectionElement);
  const sectionsToDisplay =
    kiDashboard?.elements.filter(isKISectionElement) || sectionsNotInDashboard;

  const { subscription, tenant, setupState } = useBootstrap();
  const {
    loading: loadingMetric,
    flattenMetrics,
    metrics,
    tableKeyToConnectorKey,
  } = useMetricList();

  const scrollToSection = (index: number) => {
    document
      .getElementById("mobile-section-" + index)
      ?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  useEffect(() => {
    if (dashboardsElementsAssociation.length > 0 && selectedDashboard === -2) {
      setSelectedDashboard(
        dashboardsElementsAssociation.find((d) => d.page === "ki")?.id || -1,
      );
    }
  }, [dashboardsElementsAssociation, selectedDashboard]);

  useEffect(() => {
    trackEvent(TRACKING_EVENTS.MOBILE_VERSION);
  }, []);
  return (
    <MobileLayout
      pageTitle={_`Key Indicators`}
      companyName={
        auth.outboundDemoData?.personalization?.name ||
        tenant?.company_name ||
        ""
      }
      onLogout={() => {
        auth.logout(resetViewEditor);
        navigate("/");
      }}
      headerContent={
        <Flex flexDirection="column" gap={12}>
          {!setupState?.isFinished && <DemoDataBanner />}
          <Grid gridTemplateColumns="1fr 1fr" gap={8}>
            <Select
              multiple={true}
              forcedDisplayedValue={_`Views`}
              loading={loadingViews}
              options={getViewsList(false)}
              selected={formatSelected}
              counter={formatSelected.length}
              onBackground="lightBackground"
              withCheckboxes={true}
              onChange={onChange}
              overlayFixedBottom
            />
            <Dropdown
              placeholder={
                selector.tab === FILTER_TYPES.PREDEFINED
                  ? PREFILTERS.find(
                      (p) => p.value === selector.predefinedSelection,
                    )?.label || ""
                  : selector.tab === FILTER_TYPES.RELATIVE
                  ? `Last ${selector.relativeSelection.count} ${pluralize(
                      selector.relativeSelection.count,
                      selector.relativeSelection.granularity,
                    )}`
                  : `${selector.rangeSelection.start.format(
                      "MMM DD, YY",
                    )} to ${selector.rangeSelection.end.format("MMM DD, YY")}`
              }
              onBackground="lightBackground"
              maxHeight="90vh"
              overlayFixedBottom
              block
            >
              <Box margin="-4px -8px" ref={parentRef}>
                <SmartDateFilterPicker
                  allowCompare
                  mobileVersion
                  noMaxHeight
                  onClose={() => {
                    (
                      parentRef.current?.parentElement?.parentElement
                        ?.childNodes[1] as HTMLElement | undefined
                    )?.click();
                  }}
                  asPopup={false}
                  {...{
                    range,
                    granularity,
                    matchingDayOfWeek,
                    compareRange,
                    comparePeriod,
                    selector,
                    setCompareRange,
                    setSelector,
                    setComparePeriod,
                    setMatchingDayOfWeek,
                  }}
                />
              </Box>
            </Dropdown>
          </Grid>
          <Box display="flex" flexDirection="row" overflow="auto" gap="8px">
            {sectionsNotInDashboard
              .slice(0, MOBILE_MAXIMUM_SECTIONS)
              .map((section, sectionIndex) => (
                <Chip
                  key={`section-anchor-${section.id}`}
                  onClick={() => scrollToSection(sectionIndex)}
                >
                  {section.title}
                </Chip>
              ))}
          </Box>
        </Flex>
      }
      isInstantDemo={auth.isVisitor()}
    >
      <Helmet>
        <title>{_`Key Indicators`} | Polar Analytics</title>
      </Helmet>
      {loading || loadingMetric ? (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          margin="24px 0"
        >
          <Loader />
        </Box>
      ) : (
        <Flex flexDirection="column" gap={8}>
          <Box
            borderRadius={theme.borderRadius.r4px}
            background={theme.colors.white100}
            padding="8px 12px"
          >
            <Select
              label={_`Dashboard`}
              loading={loading}
              options={[
                ...dashboardsElementsAssociation
                  .filter((d) => d.page === "ki")
                  .map((d) => ({
                    label: d.title || _`Other sections`,
                    value: d.id,
                  })),
              ]}
              selected={[selectedDashboard]}
              onBackground="lightBackground"
              onChange={(v) => setSelectedDashboard(Number(v[0]))}
              overlayFixedBottom
            />
          </Box>
          {sectionsToDisplay.map((section, sectionIndex) => (
            <Box
              key={`section-${section.id}`}
              display="flex"
              flexDirection="column"
              borderRadius={theme.borderRadius.r4px}
              background={theme.colors.white100}
              padding="8px 12px"
              gap="12px"
            >
              <div id={`mobile-section-${sectionIndex}`} />
              <Text variant="body15Regular">{section.title}</Text>
              {getVisibleMetrics(subscription, section.metrics)
                .filter((metric) => flattenMetrics[metric])
                .map((metric, i) => (
                  <MetricCardWrapper
                    key={`m-${section.id}-${metric}-${i}`}
                    sectionId={section.id}
                    metricIndex={i}
                    metricName={metric}
                    goal={sectionsNotInDashboard[sectionIndex]?.goals?.[metric]}
                    onEditGoal={() => {}}
                    label={flattenMetrics[metric].label}
                    invertedEvolution={flattenMetrics[metric].invertedEvolution}
                    description={flattenMetrics[metric].description}
                    icons={getMetricIcon(
                      metric,
                      metrics,
                      statuses,
                      tableKeyToConnectorKey,
                    )}
                    ratio={flattenMetrics[metric].ratio === true}
                    percentage={flattenMetrics[metric].percentage === true}
                    requirements={
                      flattenMetrics[metric].availableIf ||
                      flattenMetrics[metric].tableDependencies || [
                        metric.split(".")[0],
                      ]
                    }
                    mobileVersion
                  />
                ))}
            </Box>
          ))}
        </Flex>
      )}
    </MobileLayout>
  );
}
