import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

const openTab = (e: React.MouseEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.stopPropagation();
  window
    .open("https://docs.fairing.co/reference/authentication", "_blank")
    ?.focus();
};

registerFrontendIntegration({
  key: "fairing",

  category: INTEGRATION_CATEGORIES.analytics_and_attribution,
  image: "Fairing",
  label: "Fairing",
  isNewIntegrationService: true,

  dependsOnAccounts: false,
  backgroundColor: theme.customColors.fairingBg,
  borderColor: theme.customColors.fairingBorder,
  get description() {
    return _`E-commerce survey platform`;
  },
  get userInstructions() {
    return (
      <>
        {_`To learn how to find your Publishable Key and Secret Token, please read`}{" "}
        <a href="/#" onClick={openTab}>
          {_`this article`}
        </a>
      </>
    );
  },
  provider: "airbyte",
});
