export const INTERCOM_ARTICLE_IDS = {
  MANDATORY_CALL: "5693399",
  HOW_TO_IMPROVE_ORDER_COVERAGE: "8324661",
  ACTIVATE_30_DAYS_MONEY_BACK: "9805347",
};

export const openIntercomArticle = (articleId: string) =>
  (window as { Intercom?: (...args: unknown[]) => void }).Intercom?.(
    "showArticle",
    articleId,
  );

export const newWindowInterComArticle = (link: string) =>
  (window as { open?: (...args: unknown[]) => { focus: () => void } })
    .open?.(link, "_blank")
    .focus();

type IntercomOpenMode = "show" | "showMessages" | "showNewMessage";
export const openIntercom = (mode: IntercomOpenMode = "show") =>
  (window as { Intercom?: (...args: unknown[]) => void }).Intercom?.(mode);

export const setIsIntercomLauncherHidden = (hidden: boolean) => {
  try {
    (window as { Intercom?: (...args: unknown[]) => void }).Intercom?.(
      "update",
      {
        hide_default_launcher: hidden,
      },
    );
  } catch (e) {
    console.error("Could not update Intercom hidden to ", hidden, e);
  }
};
