import { CustomDimensionRecord } from "../components/CustomBreakdowns/types";
import { queryClient } from "../hooks/query/QueryProvider";
import { LOCAL_STORAGE_KEYS } from "../hooks/useLocalStorage";
import {
  PerformanceEvent,
  startPerformanceMeasurement,
} from "../hooks/usePerformanceMeasurement";
import {
  ComposeExtraParams,
  ComposeTopFilter,
  IDimension,
  MetricsListAPI,
} from "../types/synthesizer";
import { ComposeRules, formatComposeRules } from "../utils/composeUtils";
import {
  DateRange,
  dateRangeToServiceRange,
  SmartDateComparePeriod,
} from "../utils/dateUtils";
import { DimensionFilter, DimensionTypes } from "../utils/filterUtils";
import { storageMemoize } from "../utils/methodUtils";
import { SettingsDefinition } from "../utils/settingUtils";

import { createClient } from "./apiClient";
import { getCustomDimensions } from "./customDimensionService";
import { getServiceHost } from "./services";

const client = createClient("synth-service");

export interface ComposeOptions {
  breakdowns: string[];
  filters: DimensionFilter[] | null;
  settings: SettingsDefinition;
}

const COMPOSE_QUERY_KEY_PREFIX = "compose";
export const compose = async <T>(
  abortSignal: AbortSignal | undefined,
  token: string,
  metricList: string[],
  range: DateRange | undefined,
  granularity: string,
  selectedBreakdowns: string[],
  rules: ComposeRules,
  metricRules: ComposeRules | undefined,
  ordering: string,
  orderingAsc: boolean,
  views: string[] = [],
  flags: string[] = [],
  limit?: number,
  top?: ComposeTopFilter,
  computeTotal?: boolean,
  extraParams?: ComposeExtraParams,
  traceId: string = crypto.randomUUID(),
  cacheKey?: string,
) => {
  const body = {
    metricList,
    computeTotal,
    range: range ? dateRangeToServiceRange(range) : undefined,
    granularity,
    selectedBreakdowns,
    rules: formatComposeRules(rules),
    metricRules: formatComposeRules(metricRules || {}),
    ordering,
    direction: orderingAsc ? 1 : 0,
    views: views.map((v) => v.split("-").slice(0, 2).join("-")),
    flags,
    ...(limit ? { limit } : {}),
    ...(top ? { top } : {}),
    ...(extraParams ? { extraParams } : {}),
    cacheKey,
  };
  // here we restore the views into query key because the third part in the view id is needed for invalidate the query cache on the frontend
  // but for backend we only need the two-part id
  const queryKey = [COMPOSE_QUERY_KEY_PREFIX, { ...body, views }];
  const queryFn = (abortSignal?: AbortSignal) => async () => {
    const measurement = startPerformanceMeasurement({
      traceId,
      eventRecord: {
        performanceEvent: PerformanceEvent.COMPOSE_QUERY_STARTED,
        compose: body,
      },
    });
    const result = await client
      .new(abortSignal)
      .post("/api/compose")
      .auth(token)
      .headers(
        localStorage.getItem("synth-debug-mode") === "1"
          ? { "x-synth-debug-mode": "active" }
          : {},
      )
      .body(body)
      .fetch<{ data: T[] }>(traceId);

    const testIceberg = window.localStorage.getItem("test-iceberg-host") || "";
    if (testIceberg !== "") {
      try {
        const resultIceberg = await client
          .new(abortSignal)
          .post("/api/compose")
          .auth(token)
          .headers({ "x-synth-debug-iceberg": testIceberg })
          .body(body)
          .fetch<{ data: T[] }>(traceId);
        if (JSON.stringify(resultIceberg) !== JSON.stringify(result)) {
          console.warn("[ICEBERG] Iceberg and Synth results are different", {
            body,
            result,
            resultIceberg,
          });
        }
      } catch (e: unknown) {
        console.warn("[ICEBERG] Iceberg query crashed", {
          body,
          e,
        });
      }
    }
    if (result.error) {
      throw new Error(result.message);
    }

    measurement.reportTimeElapsed({
      performanceEvent: PerformanceEvent.COMPOSE_QUERY_FINISHED,
    });
    return result.data;
  };
  const state = queryClient.getQueryState(queryKey);
  if (state && state.status === "success") {
    return state.data as T[];
  }

  return await queryClient.fetchQuery({
    queryKey,
    queryFn: queryFn(abortSignal),
    staleTime: 2 * 60 * 1000,
  });
};

type ComposeStreamCompareSettings = {
  metricList: string[];
  range: DateRange;
  period: SmartDateComparePeriod | undefined;
};
export const composeStream = async ({
  token,
  metricList,
  range,
  granularity,
  selectedBreakdowns,
  rules,
  metricRules,
  ordering,
  orderingAsc,
  views = [],
  flags = [],
  top,
  extraParams,
  csvHeaders,
  filename = "data",
  compareSettings,
}: {
  token: string;
  metricList: string[];
  range: DateRange | undefined;
  granularity: string;
  selectedBreakdowns: string[];
  rules: ComposeRules;
  metricRules: ComposeRules | undefined;
  ordering: string;
  orderingAsc: boolean;
  views: string[];
  flags: string[];
  top?: ComposeTopFilter;
  extraParams?: ComposeExtraParams;
  csvHeaders: { title: string; key: string }[];
  filename?: string;
  compareSettings?: ComposeStreamCompareSettings;
}) => {
  const body = {
    metricList,
    range: range ? dateRangeToServiceRange(range) : undefined,
    compareSettings: compareSettings
      ? {
          ...compareSettings,
          range: dateRangeToServiceRange(compareSettings.range),
        }
      : undefined,
    granularity,
    selectedBreakdowns,
    rules: formatComposeRules(rules),
    metricRules: formatComposeRules(metricRules || {}),
    ordering,
    direction: orderingAsc ? 1 : 0,
    views,
    flags,
    csvHeaders,
    ...(top ? { top } : {}),
    ...(extraParams ? { extraParams } : {}),
  };

  const response = await fetch(
    getServiceHost("synth-service") + "/api/compose-stream",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token,
      },
      body: JSON.stringify(body),
    },
  );
  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${filename}.csv`;
  document.body.appendChild(a);
  a.click();
  a.remove();
  window.URL.revokeObjectURL(url);
  return blob.size;
};

export const provideDimensions = (
  tables: string[],
  dimension: string,
  searchText: string,
) => {
  const key = `${
    LOCAL_STORAGE_KEYS.DASH_VIEWS_DIMENSIONS_PROVIDER_PREFIX
  }-${tables.join("-")}-${dimension}#${searchText}`;
  return storageMemoize(key, async (token: string) => {
    const result = await client
      .new()
      .post("/api/breakdowns/values")
      .body({
        tables,
        dimension,
        searchText,
      })
      .auth(token)
      .fetch<{ data: string[] }>();
    return result.error ? [] : result.data;
  });
};

export const getFilters = async (
  abortController: AbortController | undefined,
  token: string,
  metricList: string[],
  selectedBreakdowns: string[],
  rules: ComposeRules,
) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/filters")
    .auth(token)
    .body({
      metricList,
      selectedBreakdowns,
      rules: formatComposeRules(rules),
    })
    .fetch<{ data: DimensionFilter[] | null }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getBreakdowns = async (
  abortController: AbortController | undefined,
  token: string,
  metricList: string[],
  selectedBreakdowns: string[],
  rules: ComposeRules,
) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/breakdowns")
    .auth(token)
    .body({
      metricList,
      selectedBreakdowns,
      rules: formatComposeRules(rules),
    })
    .fetch<{ data: string[] }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getComposeOptions = async (
  abortController: AbortController | undefined,
  token: string,
  metricList: string[],
  selectedBreakdowns: string[],
  rules: ComposeRules,
) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/composeOptions")
    .auth(token)
    .body({
      metricList,
      selectedBreakdowns,
      rules: formatComposeRules(rules),
    })
    .fetch<{ data: ComposeOptions }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};

type ListMetricOptions = {
  withCustomMetrics?: boolean;
  withCustomDimensions?: boolean;
};
export const listMetrics = async (
  token: string,
  options: ListMetricOptions,
  signal?: AbortSignal,
) => {
  const result = await client
    .new(signal)
    .get("/api/metrics")
    .auth(token)
    .trackDuration()
    .fetch<{ data: MetricsListAPI }>();

  const data = result.error
    ? { metrics: {}, tables: {} }
    : {
        metrics: result.data.metrics,
        tables: result.data.tables,
        customMetrics: options.withCustomMetrics
          ? result.data.customMetrics
          : {},
      };

  const output: {
    customDimensions?: { [key: string]: IDimension };
  } = {};

  if (options.withCustomDimensions) {
    const customData = await getCustomDimensions(token);
    const customDict: { [key: string]: IDimension } = {};

    customData.forEach((m) => {
      customDict[`custom_${m.id}`] = {
        key: `custom_${m.id}`,
        label: m.title,
        type: DimensionTypes.string,
        groupable: true,
        filterable: true,
        in: [],
      };
    });

    if (customData.length > 0) {
      output.customDimensions = customDict;
    }
  }

  return {
    ...data,
    ...output,
  };
};

export const previewCustomDimension = async (
  abortController: AbortController | undefined,
  token: string,
  dimension: CustomDimensionRecord,
) => {
  const result = await client
    .new(abortController?.signal)
    .post("/api/dimensions/preview")
    .auth(token)
    .body({ dimension })
    .fetch<{ data: Record<string, unknown>[] }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};

export const getMinDate = async (
  token: string,
): Promise<string | undefined> => {
  const result = await client
    .new()
    .post("/api/compose")
    .auth(token)
    .body({
      metricList: ["prepared.minDate"],
      granularity: "none",
      selectedBreakdowns: [],
      rules: {},
      flags: [],
    })
    .fetch<{ data: { mindate?: string }[] }>();

  if (result.error) {
    throw new Error(result.message);
  }
  return result.data?.[0]?.mindate;
};

export const getResourceRedirectUrl = async (
  token: string,
  connectorKey: string,
  resourceName: string,
  filter: Record<string, string>,
) => {
  const queryParams = new URLSearchParams(filter);
  const result = await client
    .new()
    .get(
      `/api/redirect/${connectorKey}/${resourceName}?${queryParams.toString()}`,
    )
    .auth(token)
    .fetch<{ data: string }>();
  if (result.error) {
    throw new Error(result.message);
  }
  return result.data;
};
