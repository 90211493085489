import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  key: "tiktok-ads",
  category: INTEGRATION_CATEGORIES.advertising,
  image: "Tiktok",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.tiktokBg,
  borderColor: theme.customColors.tiktokBorder,
  label: "Tiktok Ads",
  adPlatformChannelName: "Tiktok Ads",
  utmGuide: "6779044",
  utmCode:
    "<your_website>?utm_source=tiktok&ad_id=__CID__&campaign_id=__CAMPAIGN_ID__&utm_medium=cpc&utm_campaign=__CAMPAIGN_NAME__&utm_term=__AID__&utm_content=__CID_NAME__",
  get description() {
    return _`Online advertising platform from ${["provider", "Tiktok"]}`;
  },
  canReconnectOAuth: true,
  provider: "fivetran",
});
