import { _ } from "../languages/helper";

import {
  FILTER_TYPES,
  DATE_GRANULARITY,
  DateRange,
  getDateRangeFromPeriod,
  getDateRangeFromRelativeRange,
  getLastMonthDateRange,
  Granularity,
  DatePickerTabType,
  PrefilterType,
} from "./dateUtils";

export const getDateRangeFromSelector = (
  startOfWeek: number,
  selector: SmartFilterSelector,
  maySeeTodayData: boolean,
  minDate: string | undefined,
): DateRange => {
  switch (selector.tab) {
    case FILTER_TYPES.RELATIVE:
      return getDateRangeFromRelativeRange(
        {
          amount: selector.relativeSelection.count,
          type: selector.relativeSelection.granularity,
        },
        maySeeTodayData,
      );
    case FILTER_TYPES.PREDEFINED:
      return getDateRangeFromPeriod(
        selector.predefinedSelection,
        startOfWeek,
        maySeeTodayData,
        minDate,
      );
    default:
      return selector.rangeSelection;
  }
};

export enum RuleOperators {
  date = "DATE",
  is = "IS",
  isnot = "ISNOT",
  contains = "CONTAINS",
  notcontains = "NOTCONTAINS",
  greater = "GREATER",
  lower = "LOWER",
  isinlist = "ISINLIST",
  isnotinlist = "ISNOTINLIST",
}

export const OPERATOR_LIST = ["(", ")", "-", "+", "x", "*", "/"];

export const STRING_RULE_OPERATORS = [
  { value: RuleOperators.is, label: _`Is` },
  { value: RuleOperators.isnot, label: _`Is Not` },
  { value: RuleOperators.contains, label: _`Contains` },
  { value: RuleOperators.notcontains, label: _`Does Not Contain` },
];

const BOOLEAN_RULE_OPERATORS = [{ value: RuleOperators.is, label: _`Is` }];

const DATE_RULE_OPERATORS = [{ value: RuleOperators.date, label: "" }];

export const INTEGER_RULE_OPERATORS = [
  { value: RuleOperators.is, label: _`Is` },
  { value: RuleOperators.isnot, label: _`Is Not` },
  { value: RuleOperators.greater, label: _`Greater than` },
  { value: RuleOperators.lower, label: _`Lower than` },
];

export const RuleOperatorList = [
  { value: RuleOperators.is, label: _`Is` },
  { value: RuleOperators.isnot, label: _`Is Not` },
  { value: RuleOperators.contains, label: _`Contains` },
  { value: RuleOperators.notcontains, label: _`Does Not Contain` },
  { value: RuleOperators.isinlist, label: _`Is In List` },
  { value: RuleOperators.isnotinlist, label: _`Is Not In List` },
];

export const METRIC_RULE_OPERATORS = [
  { value: RuleOperators.is, label: _`Equal` },
  { value: RuleOperators.isnot, label: _`Not Equal` },
  { value: RuleOperators.greater, label: _`Greater than` },
  { value: RuleOperators.lower, label: _`Lower than` },
];

export interface SmartFilterSelector {
  tab: DatePickerTabType;
  predefinedSelection: PrefilterType;
  relativeSelection: {
    count: number;
    granularity: Granularity;
  };
  rangeSelection: DateRange;
}

export interface Rule {
  dimensionFilter: DimensionFilter | null;
  operator: RuleOperators;
  value: string[] | SmartFilterSelector[];
}
export interface RuleWithFilter extends Rule {
  dimensionFilter: DimensionFilter;
}

export type TypeOrArrayOfType<T> = T | T[];

export type RuleElement = TypeOrArrayOfType<Rule>;

export const isRuleGroup = <T>(ruleElement: T | T[]): ruleElement is T[] => {
  if (Array.isArray(ruleElement)) {
    return true;
  }
  return false;
};

export enum DimensionTypes {
  integer = "integer",
  string = "string",
  boolean = "boolean",
  date = "date",
  datetime = "datetime",
}

export const getOperatorListOptionsForType = (
  type: DimensionTypes,
): { value: RuleOperators; label: string }[] => {
  switch (type) {
    case DimensionTypes.integer:
      return INTEGER_RULE_OPERATORS;
    case DimensionTypes.string:
      return STRING_RULE_OPERATORS;
    case DimensionTypes.boolean:
      return BOOLEAN_RULE_OPERATORS;
    case DimensionTypes.date:
    case DimensionTypes.datetime:
      return DATE_RULE_OPERATORS;
    default:
      throw new Error(
        "getOperatorListOptionsForType: Type '" +
          (type as string) +
          "' not supported",
      );
  }
};

const getDefaultOperatorForType = (
  dimensionFilter: DimensionFilter,
): RuleOperators => {
  if (dimensionFilter?.defaultOperator) {
    return dimensionFilter?.defaultOperator ?? RuleOperators.is;
  }
  const operators = getOperatorListOptionsForType(dimensionFilter.type);
  return operators[0]?.value ?? RuleOperators.contains;
};

const getDefaultValueForRule = (
  dimensionFilter: DimensionFilter,
): Rule["value"] => {
  if (dimensionFilter?.defaultValues) {
    return dimensionFilter?.defaultValues ?? [];
  }
  switch (dimensionFilter.type) {
    case DimensionTypes.integer:
    case DimensionTypes.string:
    case DimensionTypes.boolean:
      return [];
    case DimensionTypes.date:
    case DimensionTypes.datetime:
      return [
        {
          tab: "relative",
          predefinedSelection: "yesterday",
          relativeSelection: {
            count: 12,
            granularity: DATE_GRANULARITY.MONTH,
          },
          rangeSelection: getLastMonthDateRange(),
        },
      ];
    default:
      throw new Error(
        `getDefaultValueForRule: Type '${
          dimensionFilter.type as string
        }' not supported`,
      );
  }
};

export interface DimensionFilter {
  name: string;
  label: string;
  type: DimensionTypes;
  connectorDependencies: string[];
  dependencies: string[];
  isDynamic: boolean;
  operators?: RuleOperators[];
  valueLabels?: string[];
  defaultOperator?: RuleOperators;
  defaultValues?: string[];
}

export const getDefaultRuleForDimension = (
  dimensionFilter: DimensionFilter,
) => {
  const defaultOperator = getDefaultOperatorForType(dimensionFilter);
  const defaultValue = getDefaultValueForRule(dimensionFilter);
  return { operator: defaultOperator, value: defaultValue };
};

export const getDefaultMetricRule = () => {
  return { operator: RuleOperators.greater, value: [] };
};
