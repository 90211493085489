import { useQuery } from "@tanstack/react-query";
import moment from "moment/moment";
import { useMemo } from "react";

import { getMinDate } from "../lib/synthService";
import { demoDateBoundaries } from "../utils/dateUtils";
import { isFreeOrTrialPlan } from "../utils/subscriptionsUtils";

import { useAuth } from "./auth/auth";
import { useBootstrap } from "./bootstrap";

const useQueryMinDateQuery = () => {
  const auth = useAuth();
  const { subscription, isDemoData } = useBootstrap();
  const isFreeOrTrial = isFreeOrTrialPlan(subscription);

  return useQuery(["date", "minDate"], async () => {
    if (isDemoData) {
      return demoDateBoundaries.start;
    } else if (isFreeOrTrial) {
      return moment(subscription?.created_at).subtract(1, "year");
    } else {
      return moment(await getMinDate(await auth.getToken()));
    }
  });
};
export const useDatePickerMinMaxDate = () => {
  const { data: minDate } = useQueryMinDateQuery();
  const { isDemoData, hasPermission } = useBootstrap();
  const maySeeTodayData = hasPermission("data.seeTodayData");
  const maxDate = useMemo(() => {
    if (isDemoData) {
      return demoDateBoundaries.end;
    } else if (maySeeTodayData) {
      return moment().endOf("day");
    } else {
      return moment().subtract(1, "day").endOf("day");
    }
  }, [isDemoData, maySeeTodayData]);
  return { maxDate, minDate };
};
