import { useState } from "react";

import {
  PlanSelector,
  SUBSCRIPTION_TYPE_NAMES,
} from "../../../common/types/subscription-service";
import { Text, Space, theme, ConfirmPopup, Stack } from "../../../icecube-ux";
import { _ } from "../../../languages/helper";
import { trackEvent, TRACKING_EVENTS } from "../../../utils/trackingUtils";

export type ConfirmationProperties = {
  onDecline: () => void;
  onConfirm: () => Promise<void>;
  planSelector: PlanSelector;
};

type UpgradeConfirmationPopupProps = {
  properties: ConfirmationProperties;
  trackingProperties?: Record<string, unknown>;
};

const UpgradeConfirmationPopup = ({
  properties: { onDecline, onConfirm, planSelector },
  trackingProperties,
}: UpgradeConfirmationPopupProps) => {
  const [isLoading, setIsLoading] = useState(false);

  const planLabel =
    planSelector.plan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLAN
      ? _`Analyze`
      : planSelector.plan === SUBSCRIPTION_TYPE_NAMES.USAGE_BASED_PLUS_PLAN
      ? _`Analyze & Enrich`
      : "";

  return (
    <ConfirmPopup
      onDecline={onDecline}
      onConfirm={() => {
        void (async () => {
          setIsLoading(true);
          trackEvent(TRACKING_EVENTS.GMV_PLAN_CHANGE_CONFIRM_CLICKED, {
            ...planSelector,
            ...trackingProperties,
          });
          await onConfirm();
          setIsLoading(false);
        })();
      }}
      isLoading={isLoading}
      confirmLabel={_`Yes, confirm upgrade`}
    >
      <Stack alignItems="center">
        <Text variant="body18Medium" color={theme.colors.text110}>
          {_`Upgrade Your Plan`}
        </Text>
        <Space size={2} />
        <Text variant="body11Regular" color={theme.colors.primary80}>
          {_`Pricing Based on Your GMV`}
        </Text>
        <Space />
        <Text
          variant="body12Regular"
          color={theme.colors.text90}
          textAlign="center"
          style={{ paddingLeft: 4, paddingRight: 4 }}
        >
          {_`Upgrading will cause your Stripe subscription to automatically reflect your new ${[
            "plan-label",
            planLabel,
          ]} plan.`}
        </Text>
      </Stack>
    </ConfirmPopup>
  );
};

export default UpgradeConfirmationPopup;
