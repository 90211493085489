import React, {
  MouseEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react";

import useOutsideClickHandler from "../hooks/useOutsideClickHandler";

const MIN_HEIGHT_MARGIN = 300;
const MAX_HEIGHT_MARGIN = 300;

export default function useSelect(
  selected: Array<string | number>,
  optionsCount: number,
  multiple?: boolean,
  onClick?: MouseEventHandler,
  onChange?: (
    values: Array<string | number>,
    changes?: { addedValue: string | number },
  ) => void,
  onOpen?: () => boolean | void,
  selectLimit?: number,
  closeWhenSelectionChange?: boolean,
  disabled?: boolean,
  forceArrow?: boolean,
) {
  const [open, setOpen] = useState(false);
  const [node, setNode] = useState<HTMLDivElement | null>(null);
  const [width, setWidth] = useState(0);
  const [scrollId, setScrollId] = useState(0);
  const [listPosition, setListPosition] = useState<React.CSSProperties>({});
  const [windowDimension, setWindowDimension] = useState({
    height: 0,
    width: 0,
  });

  const refCallback = useCallback((element: HTMLDivElement) => {
    setNode(element);
  }, []);

  const handleClickOnElement = (value: string | number) => {
    if (!onChange) {
      return;
    }

    if (!selected.includes(value)) {
      if (selectLimit !== undefined) {
        selected.length < selectLimit &&
          onChange(multiple ? [...selected, value] : [value], {
            addedValue: value,
          });
      } else {
        onChange(multiple ? [...selected, value] : [value], {
          addedValue: value,
        });
      }
    } else {
      onChange(multiple ? selected.filter((v) => v !== value) : [value]);
    }

    if (closeWhenSelectionChange) {
      setOpen(false);
    }
  };

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(e);
    }

    setOpen((o) => {
      let openFuncResult;
      if (!o && onOpen) {
        openFuncResult = onOpen();
        if (openFuncResult === false) {
          return false;
        }
      }
      setWidth(node?.clientWidth || 0);
      return !disabled && (optionsCount > 0 || forceArrow === true)
        ? !o
        : false;
    });
  };

  const xBoundingRect = node?.getBoundingClientRect().x;
  const yBoundingRect = node?.getBoundingClientRect().y;

  useEffect(() => {
    if (!node) {
      return;
    }

    const rect = node.getBoundingClientRect();
    if (windowDimension.height - rect.y < MIN_HEIGHT_MARGIN) {
      setListPosition({
        left: Math.max(0, rect.x),
        bottom: windowDimension.height - rect.y + 8,
        minWidth: rect.width,
      });
      return;
    }
    setListPosition({
      left: Math.max(0, rect.x),
      top: rect.y + rect.height + 8,
      minWidth: rect.width,
      maxHeight: Math.min(
        MAX_HEIGHT_MARGIN,
        windowDimension.height - (rect.y + rect.height),
      ),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    node,
    width,
    xBoundingRect,
    yBoundingRect,
    windowDimension.height,
    windowDimension.width,
    window.scrollY,
    window.scrollX,
    scrollId,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const scrollListener = (e: Event) => {
      if (node && node !== e.target && !node.contains(e.target as Node)) {
        setOpen(false);
        setScrollId((c) => c + 1);
      }
    };

    document.addEventListener("scroll", scrollListener, true);
    return () => {
      document.removeEventListener("scroll", scrollListener);
    };
  }, [node]);

  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  useOutsideClickHandler(close, node);

  return {
    refCallback,
    listPosition,
    open,
    setOpen,
    handleClick,
    handleClickOnElement,
  };
}
