import { theme } from "../icecube-ux";
import { _ } from "../languages/helper";
import { INTEGRATION_CATEGORIES } from "../utils/integrationUtils";

import { registerFrontendIntegration } from "./integration";

registerFrontendIntegration({
  category: INTEGRATION_CATEGORIES.advertising,
  key: "amazon-ads",
  image: "Amazon",
  dependsOnAccounts: true,
  backgroundColor: theme.customColors.amazonBg,
  borderColor: theme.customColors.amazonBorder,
  label: "Amazon Ads",
  adPlatformChannelName: "Amazon Ads",
  get description() {
    return _`Online advertising platform from ${["provider", "Amazon"]}`;
  },
  canReconnectOAuth: true,
  provider: "fivetran",
});
