import classNames from "classnames";
import React, {
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
  CSSProperties,
} from "react";

import { _ } from "../../languages/helper";
import { Box } from "../Box";
import { Button } from "../Button";
import { Counter } from "../Counter";
import { Grid } from "../Grid";
import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { List, ListOptions } from "../List";
import { Loader } from "../Loader";
import { ClassAndStyleProps, InteractionEvents } from "../shared";
import { OnBackground } from "../shared/baseInputFieldColorMap";
import { TextFieldSize } from "../shared/baseInputFieldSizeStylesMap";
import InputWrapper from "../shared/InputWrapper";
import { TextField as Input } from "../TextField";
import { theme } from "../theme";
import { stopPropagation } from "../utils";

import useSelect from "./Select.hooks";
import {
  StyledSelect,
  StyledSelectAddButton,
  StyledSelectIconRight,
  StyledSelectListFooter,
  StyledSelectListScroller,
  StyledSelectMainContent,
  StyledSelectPlaceholder,
  StyledSelectValueDisplay,
  StyleSelectList,
  SyledFixedBottomShadow,
} from "./Select.styled";

const fixedBottomListPosition = {
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: theme.zIndexes.layer6,
};

const hoverStyle = {
  background: theme.colors.primary10,
  margin: "0 -12px",
  borderRadius: "0",
  padding: "0 16px",
  color: theme.colors.primary100,
};

const shouldDisplayLeftIcon = (
  leftIcon: IconNames | undefined,
  onlyShowIconOnOpen: boolean,
  open: boolean,
) => {
  if (!leftIcon) {
    return false;
  }
  if (onlyShowIconOnOpen) {
    return open;
  }
  return true;
};

export type SelectProps = InteractionEvents<HTMLDivElement> &
  ClassAndStyleProps & {
    label?: string;
    counter?: number;
    block?: boolean;
    disabled?: boolean;
    canUnselectWhenDisabled?: boolean;
    disableList?: boolean;
    loading?: boolean;
    notOpenable?: boolean;
    leftIcon?: IconNames;
    leftComponent?: ReactNode;
    withSearch?: boolean;
    initialSearch?: string;
    onSearchChange?: (value: string) => void;
    withAddButton?: boolean;
    onClickAddButton?: MouseEventHandler<HTMLDivElement>;
    onOpen?: () => void;
    footerAction?: { label: string | React.ReactElement; onClick: () => void };
    footerInfoComponent?: ReactNode;
    options: ListOptions[];
    selected: Array<string | number>;
    selectLimit?: number;
    selectedOptionsOnTop?: boolean;
    withCheckboxes?: boolean;
    withRadio?: boolean;
    multiple?: boolean;
    onChange?: (values: Array<string | number>) => void;
    iconMode?: boolean;
    hideLabel?: boolean;
    closeWhenSelectionChange?: boolean;
    dataAttributes?: { [key: string]: string };
    size?: TextFieldSize;
    onBackground?: OnBackground;
    error?: string;
    helpText?: string;
    placeholder?: ReactNode;
    forceArrow?: boolean;
    name?: string;
    forcedDisplayedValue?: ReactNode;
    showSelectAll?: boolean;
    overlayFixedBottom?: boolean;
    displayLimit?: number;
    primary?: boolean;
    secondary?: boolean;
    listStyles?: CSSProperties;
    wrapperStyle?: CSSProperties;
    onlyShowIconOnOpen?: boolean;
  };

export default function Select({
  label = "",
  counter,
  block = false,
  disabled = false,
  primary = false,
  secondary = false,
  canUnselectWhenDisabled = false,
  disableList = false,
  onlyShowIconOnOpen = false,
  showSelectAll,
  loading,
  notOpenable,
  onOpen,
  leftIcon,
  leftComponent,
  withSearch,
  initialSearch,
  onSearchChange,
  withAddButton,
  onClickAddButton,
  className,
  style,
  options,
  selected,
  withCheckboxes,
  withRadio,
  onChange,
  multiple,
  footerAction,
  footerInfoComponent,
  iconMode,
  hideLabel,
  closeWhenSelectionChange,
  placeholder,
  selectLimit,
  selectedOptionsOnTop,
  dataAttributes,
  name,
  forceArrow,
  size = "large",
  onBackground = "whiteBackground",
  error,
  helpText,
  forcedDisplayedValue,
  overlayFixedBottom,
  displayLimit = 3,
  listStyles,
  wrapperStyle,
  ...interactionEvents
}: SelectProps) {
  const {
    refCallback,
    listPosition,
    open,
    setOpen,
    handleClick,
    handleClickOnElement,
  } = useSelect(
    selected,
    options?.length,
    multiple,
    interactionEvents.onClick,
    onChange,
    onOpen,
    selectLimit,
    closeWhenSelectionChange,
    disabled,
    forceArrow,
  );

  const displayValue =
    forcedDisplayedValue !== undefined
      ? forcedDisplayedValue
      : selected.length > 0 && options.length > 0
      ? selected
          .slice(0, displayLimit)
          .map((selectedId) => {
            const element = options.find((o) => o.value === selectedId);
            return element?.searchableLabel || element?.label;
          })
          .filter((exist) => !!exist)
          .join(", ") +
        (selected.length > displayLimit
          ? `, +${selected.length - displayLimit} more`
          : "")
      : "";

  const [search, setSearch] = useState("");
  const [focusedElement, setFocusedElement] = useState<string | number | null>(
    null,
  );

  useEffect(() => {
    if (initialSearch && search === "") {
      setSearch(initialSearch);
    }
  }, [initialSearch, search]);

  useEffect(() => {
    if (!open && search !== "") {
      setSearch("");
    }
  }, [open, search]);

  const shouldDisplaySearchInput = withSearch && open;

  const showLeftIcon = shouldDisplayLeftIcon(
    leftIcon,
    onlyShowIconOnOpen,
    open,
  );

  return (
    <InputWrapper
      className={className}
      style={wrapperStyle}
      {...{ label, error, isFocused: open, disabled, helpText }}
    >
      <StyledSelect
        {...interactionEvents}
        ref={refCallback}
        style={style}
        className={classNames(className, { disabled, open, hideLabel })}
        onClick={handleClick}
        {...dataAttributes}
        {...{
          withBackground: onBackground,
          secondary,
          size,
          disabled,
          error,
          open,
          block,
          name,
        }}
      >
        <StyledSelectMainContent
          template={
            iconMode && !shouldDisplaySearchInput
              ? "auto"
              : [
                  leftComponent ? "auto" : "",
                  showLeftIcon ? "auto" : "",
                  counter !== undefined && counter > 0 ? "auto" : "",
                  "1fr",
                  (!iconMode && options?.length > 0) || forceArrow === true
                    ? "auto"
                    : "",
                ].join(" ")
          }
          {...{ size }}
        >
          {leftComponent && <Box marginLeft="-4px">{leftComponent}</Box>}
          {showLeftIcon && leftIcon && (
            <Box margin="0 -4px" lineHeight="11px">
              <Icon name={leftIcon} />
            </Box>
          )}
          {counter !== undefined && counter > 0 && (
            <Counter value={counter} disabled={disabled} variant="small" />
          )}
          {!iconMode && displayValue && !shouldDisplaySearchInput && (
            <StyledSelectValueDisplay {...{ primary, secondary, disabled }}>
              {displayValue}
            </StyledSelectValueDisplay>
          )}
          {!iconMode && !displayValue && !shouldDisplaySearchInput && (
            <StyledSelectPlaceholder disabled={disabled}>
              {placeholder}
            </StyledSelectPlaceholder>
          )}
          {shouldDisplaySearchInput && (
            <Input
              autoComplete="off"
              autoFocus
              transparent
              value={search}
              placeholder={_`Search...`}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  if (focusedElement !== null) {
                    onChange &&
                      onChange(
                        multiple
                          ? [...selected, focusedElement]
                          : [focusedElement],
                      );
                  } else if (!multiple) {
                    onChange && onChange([]);
                  }
                  setSearch("");
                  setOpen(false);
                }
              }}
              onChange={(value) => {
                setSearch(`${value}`);
                onSearchChange && onSearchChange(`${value}`);
              }}
            />
          )}
          {!iconMode && (options?.length > 0 || forceArrow === true) && (
            <StyledSelectIconRight>
              {!loading && (
                <Icon name="Dropdown" size={18} className="droper" />
              )}
              {loading && <Loader size={12} />}
            </StyledSelectIconRight>
          )}
        </StyledSelectMainContent>
        {withAddButton && (
          <StyledSelectAddButton
            {...{ withBackground: onBackground }}
            onClick={(e) => {
              if (onClickAddButton && !disabled) {
                onClickAddButton(e);
              }
              return stopPropagation(e);
            }}
          >
            <Icon name="Add" />
          </StyledSelectAddButton>
        )}
        {open && !notOpenable && overlayFixedBottom && (
          <SyledFixedBottomShadow />
        )}
        {open && !notOpenable && (
          <StyleSelectList
            style={{
              ...(overlayFixedBottom ? fixedBottomListPosition : listPosition),
              ...listStyles,
            }}
            onClick={(e) => {
              if (!multiple && (e.target as HTMLElement).tagName !== "INPUT") {
                setOpen(false);
              }
              stopPropagation(e);
            }}
          >
            <Grid gridTemplateRows="1fr auto" style={{ maxHeight: "300px" }}>
              <StyledSelectListScroller
                style={{ overscrollBehavior: "contain" }}
              >
                <List
                  loading={loading}
                  options={options.map((o) => ({
                    ...o,
                    isDisabled:
                      o.isDisabled ||
                      (selectLimit !== undefined &&
                        selected.length >= selectLimit &&
                        !selected.includes(o.value)),
                  }))}
                  selected={selected}
                  disabled={disableList}
                  canUnselectWhenDisabled={canUnselectWhenDisabled}
                  withCheckboxes={withCheckboxes}
                  withRadio={withRadio}
                  onClickOnElement={handleClickOnElement}
                  onSelectionChange={onChange}
                  selectedOptionsOnTop={selectedOptionsOnTop}
                  showSelectAll={showSelectAll}
                  passedSearch={search}
                  onFocusElementChange={setFocusedElement}
                  optionHoverStyle={hoverStyle}
                />
              </StyledSelectListScroller>
              {footerAction && (
                <StyledSelectListFooter>
                  <Button
                    onClick={footerAction.onClick}
                    color="secondary"
                    block
                  >
                    {footerAction.label}
                  </Button>
                </StyledSelectListFooter>
              )}

              {footerInfoComponent ? (
                <StyledSelectListFooter>
                  {footerInfoComponent}
                </StyledSelectListFooter>
              ) : (
                <></>
              )}
            </Grid>
          </StyleSelectList>
        )}
      </StyledSelect>
    </InputWrapper>
  );
}
