import classNames from "classnames";
import React from "react";

import { Box } from "../Box";
import { ConnectorIconNames } from "../ConnectorIcon";
import ConnectorIcon from "../ConnectorIcon/ConnectorIcon";
import { Icon } from "../Icon";
import { IconNames } from "../Icon/Icon";
import { IconButton } from "../IconButton";
import { ClassAndStyleProps, InteractionEvents } from "../shared";

import {
  ActionsContainer,
  StyledChip,
  StyledChipCount,
  StyledChipTitle,
} from "./Chip.styled";

interface ChipAction {
  label: string;
  icon: IconNames;
  onClick: () => void;
}

export type ChipProps = Omit<InteractionEvents<HTMLButtonElement>, "onClick"> &
  ClassAndStyleProps & {
    children?: React.ReactNode;
    label?: string;
    selected?: boolean;
    disabled?: boolean;
    size?: "large" | "small" | "xsmall" | "xxsmall";
    canDrag?: boolean;
    connectorIconName?: ConnectorIconNames;
    onBackground?: "white" | "light" | "black";
    align?: "left" | "center";
    leftIcon?: IconNames;
    rightIcon?: IconNames;
    count?: number;
    actions?: ChipAction[];
    hovered?: boolean;
    warning?: boolean;
    displayActionOnHover?: boolean;
    // Allow for the shorthand syntax: isOpen && () => { close() }
    onClick?: false | InteractionEvents<HTMLButtonElement>["onClick"];
  } & (
    | {
        leftIcon?: IconNames;
        leftSquare?: undefined;
      }
    | {
        leftSquare: { backgroundColor?: string; border?: string };
        leftIcon?: undefined;
      }
  );

const SQUARE_SIZES = {
  large: 11,
  small: 9,
  xsmall: 6,
  xxsmall: 5,
};

const ICON_SIZES = {
  large: 14,
  small: 14,
  xsmall: 9,
  xxsmall: 9,
};

export default function Chip({
  children,
  label,
  selected = false,
  disabled = false,
  hovered = false,
  className,
  style,
  size = "large",
  onBackground = "white",
  canDrag = false,
  count,
  connectorIconName,
  align = "center",
  leftIcon,
  rightIcon,
  leftSquare,
  actions,
  displayActionOnHover,
  ...interactionEvents
}: ChipProps) {
  const onClick = interactionEvents.onClick || undefined;

  return (
    <StyledChip
      {...interactionEvents}
      canDrag={canDrag}
      onClick={disabled ? undefined : onClick}
      align={align}
      className={classNames(className)}
      style={style}
      disabled={disabled}
      background={onBackground}
      hovered={hovered}
      displayActionOnHover={displayActionOnHover}
      {...{ size, selected, noContent: !(children || label) }}
    >
      {count && (
        <StyledChipCount className="margin-right-small">
          {count}
        </StyledChipCount>
      )}
      {canDrag && (
        <Icon
          name="Dragger"
          className="margin-right-small"
          size={ICON_SIZES[size]}
        />
      )}
      {leftIcon ? (
        <Icon
          name={leftIcon}
          size={ICON_SIZES[size]}
          className="margin-right-small"
        />
      ) : leftSquare ? (
        <Box
          boxSizing="border-box"
          backgroundColor={leftSquare.backgroundColor}
          border={leftSquare.border}
          width={SQUARE_SIZES[size]}
          height={SQUARE_SIZES[size]}
          marginRight={5}
          borderRadius={2}
        />
      ) : null}
      {connectorIconName && (
        <ConnectorIcon
          name={connectorIconName}
          size={9}
          className="margin-right-small"
        />
      )}
      <StyledChipTitle>{children || label}</StyledChipTitle>
      {rightIcon && (
        <Icon
          name={rightIcon}
          size={ICON_SIZES[size]}
          className="margin-left-small"
        />
      )}
      {actions && (
        <ActionsContainer>
          {actions.map((action, index) => (
            <IconButton
              key={action.icon + index}
              name={action.icon}
              onClick={action.onClick}
            />
          ))}
        </ActionsContainer>
      )}
    </StyledChip>
  );
}
